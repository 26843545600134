import { observable, action, makeAutoObservable, computed } from 'mobx';
import {
    AllowedFilter,
    AppliedFilter,
    CustomerView,
    ExplorerConfig,
    FilterOperators,
    LogicalOperator,
    MinClusterSizeResponse,
    MosaicMetadata,
    UserDetails,
} from '../types';
import React, { createContext } from 'react';
import { CLUSTER_SIZE_FILTER_NAME } from '../helper/consts';
import { CUSTOM_OPERATORS } from '../components/Dashboard/filtersHelper';
import isEqual from 'react-fast-compare';

class MosaicStore {
    @observable
    filters: AppliedFilter[] | undefined = undefined;

    @observable
    currentView: CustomerView | undefined = undefined;

    @observable
    availableViews: CustomerView[] | undefined = undefined;

    @observable
    filterOperators: FilterOperators | undefined = undefined;

    @observable
    userDetails: UserDetails | undefined = undefined;

    @observable
    mosaicMetadata: MosaicMetadata | undefined = undefined;

    @observable
    config: ExplorerConfig | undefined = undefined;

    @observable
    filterOperatorsLoaded: boolean = false;

    @observable
    filterOperatorsIsLoading: boolean = false;

    @observable
    filtersLogicalOperator: LogicalOperator | undefined = undefined;

    @observable
    isDataLoading: boolean = true;

    @observable
    isFilterPopoverOpen: boolean = false;

    @observable
    minClusterSizeResponse: MinClusterSizeResponse | undefined = undefined;

    @observable
    selectedMinClusterSize: number | undefined = undefined;

    @action
    public setSelectedMinClusterSize(newSelectedMinClusterSize: number | undefined) {
        this.selectedMinClusterSize = newSelectedMinClusterSize;
    }

    @action
    public setMinClusterSizeResponse(newMinClusterSizeResponse: MinClusterSizeResponse | undefined) {
        const isPreloaded =
            newMinClusterSizeResponse &&
            this.minClusterSizeResponse === undefined &&
            this.selectedMinClusterSize !== undefined &&
            this.selectedMinClusterSize >= newMinClusterSizeResponse.min &&
            (newMinClusterSizeResponse.max === undefined ||
                this.selectedMinClusterSize <= newMinClusterSizeResponse.max);

        if (
            !isPreloaded &&
            newMinClusterSizeResponse &&
            !isEqual(newMinClusterSizeResponse, this.minClusterSizeResponse)
        ) {
            // Updating the selected if the min cluster sizes were changed
            this.selectedMinClusterSize = newMinClusterSizeResponse.default;
        }

        this.minClusterSizeResponse = newMinClusterSizeResponse;
    }

    @action
    public setIsFilterPopoverOpen(newIsFilterPopoverOpen: boolean) {
        this.isFilterPopoverOpen = newIsFilterPopoverOpen;
    }

    @action
    public setFiltersLogicalOperator(newFiltersLogicalOperator: LogicalOperator | undefined) {
        this.filtersLogicalOperator = newFiltersLogicalOperator;
    }

    @action
    public applyFilters(
        updatedFilters: AppliedFilter[] | undefined,
        newFiltersLogicalOperator: LogicalOperator | undefined,
    ) {
        this.filters = updatedFilters;
        this.filtersLogicalOperator = newFiltersLogicalOperator;
    }

    @action
    public setIsDataLoading(newIsDataLoading: boolean) {
        this.isDataLoading = newIsDataLoading;
    }

    @action
    public resetConfig() {
        if (!this.mosaicMetadata || !this.config) {
            return;
        }

        const defaultGroupBy = [this.mosaicMetadata.view.group_by.attributes[0]].filter(Boolean);
        this.config = {
            groupBy: defaultGroupBy,
            sizeBy: {
                attribute: this.mosaicMetadata.view.size_by.attributes[0],
                fn: this.mosaicMetadata.view.size_by.fn,
            },
            colorBy: {
                attribute: this.mosaicMetadata.view.color_by.attributes[0],
                fn: this.mosaicMetadata.view.color_by.fn,
                palette: this.mosaicMetadata.view.color_by.palette,
            },
        };
    }

    @action
    public setConfigWithFunction(
        newConfigFn: (config: ExplorerConfig | undefined) => ExplorerConfig | undefined,
        afterUpdateCallback: () => void = () => {},
    ) {
        this.config = newConfigFn(this.config);
        afterUpdateCallback();
    }

    @action
    public setConfig(newConfig: ExplorerConfig | undefined) {
        this.config = newConfig;
    }

    @action
    public setMosaicMetadata(newMosaicMetadata: MosaicMetadata | undefined) {
        this.mosaicMetadata = newMosaicMetadata;
    }

    @action
    public setFilters(updatedFilters: AppliedFilter[] | undefined) {
        this.filters = updatedFilters;
    }

    @action
    public setCurrentView(newCustomerView: CustomerView) {
        this.currentView = newCustomerView;
    }

    @action
    public setAvailableViews(newAvailableViews: CustomerView[]) {
        this.availableViews = newAvailableViews;
    }

    @action
    public loadFilterOperators(newFilterOperators: FilterOperators) {
        this.filterOperators = { ...newFilterOperators, ...CUSTOM_OPERATORS };
        this.filterOperatorsLoaded = true;
        this.filterOperatorsIsLoading = false;
    }

    @action
    public startFilterOperatorsLoading() {
        this.filterOperatorsLoaded = false;
        this.filterOperatorsIsLoading = true;
    }

    @action
    public setUserDetails(newUserDetails: UserDetails) {
        this.userDetails = newUserDetails;
    }

    @action
    public logout() {
        this.userDetails = undefined;
    }

    @action
    public reset() {
        this.filters = undefined;
        this.currentView = undefined;
        this.config = undefined;
        this.mosaicMetadata = undefined;
    }

    @computed
    get allowedFilters(): AllowedFilter[] | undefined {
        return this.mosaicMetadata?.dataset.filter_fields;
    }

    @computed
    get currentAppliedFiltersWithoutClusterSize(): AppliedFilter[] {
        return (this.filters || this.mosaicMetadata?.view.filters || []).filter(
            (f) => f.field !== CLUSTER_SIZE_FILTER_NAME,
        );
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default MosaicStore;

export const MosaicStoreContext = createContext<MosaicStore | null>(null);

interface UnknownComponentProps extends React.HTMLAttributes<unknown> {}

export const MosaicStoreContextProvider: React.FC<UnknownComponentProps> = (props) => {
    return <MosaicStoreContext.Provider value={new MosaicStore()}>{props.children}</MosaicStoreContext.Provider>;
};
