import { Field } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSliderComponent from '../../../common/CustomSlider';
import { debounce } from 'lodash';
import Box from '@mui/material/Box';
import GroupTitle from '../GroupTitle';

interface SliderSelectorProps {
    name: string;
    fieldName: string;
    disabled?: boolean;
    valueError?: string;
    min: number;
    max: number;
    submitDisabled: boolean;
    submitForm: () => void;
}

const createCustomSliderComponent = (min: number, max: number, submitForm: () => void) => (props: any) => {
    return (
        <CustomSliderComponent
            name={props.field.name}
            value={props.field.value}
            onChange={(e) => {
                props.field.onChange(e);
            }}
            onChangeCommitted={() => submitForm()}
            disabled={props.disabled}
            min={min}
            max={max}
            marks
            showValueLabel
            showMinMaxValues
        />
    );
};

const SliderSelector: React.FC<SliderSelectorProps> = (props) => {
    const { name, fieldName, disabled, valueError, min, max, submitDisabled, submitForm } = props;
    const { t } = useTranslation();
    const translatedFieldName = t(`filters.commonFilterFields.${fieldName}`, fieldName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSubmitForm = useCallback(
        debounce(() => {
            if (!submitDisabled) {
                submitForm();
            }
        }, 700),
        [submitForm, submitDisabled],
    );
    const CustomSlider = useMemo(
        () => createCustomSliderComponent(min, max, debouncedSubmitForm),
        [min, max, debouncedSubmitForm],
    );

    return (
        <div>
            {/* Field Name */}
            <GroupTitle>{translatedFieldName}</GroupTitle>
            {/* Value */}
            <Box sx={{ m: 1 }}>
                <Field name={name} component={CustomSlider} disabled={disabled} error={Boolean(valueError)} />
            </Box>
        </div>
    );
};

export default SliderSelector;
