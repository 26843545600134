import { JWT_TOKEN_COOKIE_NAME } from '../components/consts';
import cookies from './cookies';
import { decodeToken } from 'react-jwt';
import { DecodedJWT } from '../types';
import api from '../api';

/**
 * Handling after successful login:
 *
 * * Storing the token as a cookie
 * * Setting the token for the future API requests
 *
 * @param token
 */
const login = (token: string) => {
    const dToken = decodeToken(token) as DecodedJWT;

    cookies.set(JWT_TOKEN_COOKIE_NAME, token, { path: '/', expires: new Date(dToken.exp * 1000) });
    api.setToken(token);
};

export default login;
