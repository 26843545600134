export const itemsHeader = 'Example Items';
export const subHeaders = {
    ofTotal: 'of Total',
};

export const title = 'Items';
export const totalItems = {
    all: 'Showing {{count}}',
    part: 'Showing {{count}} of {{total}}',
};
