import React from 'react';
import { useTranslation } from 'react-i18next';
import { palettes } from '../../../helper/consts';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MathFunctionSlider from './MathFunctionSlider';
import getColors from '../../../helper/getColor';
import style from './ColorBySelector.module.scss';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import GroupTitle from './GroupTitle';
import { BASE_SX } from './consts';
import { ClusterFieldNamesMapping } from '../../../types';

/**
 * Generate a linear gradient for pallet overview
 *
 * @param pallet
 * @returns
 */
const createLinearGradientForPallet = (pallet: string) => {
    const colors = getColors(pallet);
    return `linear-gradient(to top, ${colors.map((c: string) => `#${c}`).join(',')})`;
};

interface ColorBySelectorProps {
    fields: string[];
    selectedField: string;
    setSelectedField: (field: string) => void;
    selectedPalette: string;
    setSelectedPalette: (palette: string) => void;
    selectedFunction: string;
    setSelectedFunction: (fnName: string) => void;
    disabled: boolean;
    clusterFieldNamesMapping: ClusterFieldNamesMapping | undefined;
}

const ColorBySelector = (props: ColorBySelectorProps) => {
    const {
        fields,
        selectedField,
        selectedPalette,
        selectedFunction,
        disabled,
        clusterFieldNamesMapping,
        setSelectedField,
        setSelectedPalette,
        setSelectedFunction,
    } = props;
    const { t } = useTranslation();

    return (
        <div className={style.commonWrapper}>
            <GroupTitle>{t('explorerConfigPanel.configHeaders.colorBy')}</GroupTitle>
            <Box>
                <Box sx={{ display: 'flex' }}>
                    {/* Fields */}
                    <FormControl size="small">
                        <Select
                            value={selectedField}
                            disabled={disabled}
                            onChange={(e: SelectChangeEvent) => setSelectedField(e.target.value)}
                            sx={{ minWidth: 170, mr: 1, ...BASE_SX }}
                        >
                            {fields.map((f) => {
                                const displayValue = clusterFieldNamesMapping ? clusterFieldNamesMapping[f] || f : f;

                                return (
                                    <MenuItem value={f} key={f} sx={BASE_SX}>
                                        {displayValue}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    {/* Palettes */}
                    <FormControl size="small">
                        <Select
                            value={selectedPalette}
                            disabled={disabled}
                            onChange={(e) => setSelectedPalette(e.target.value)}
                            sx={{ minWidth: 170, ...BASE_SX }}
                        >
                            {palettes.map((p) => {
                                const gradient = createLinearGradientForPallet(p.name);

                                return (
                                    <MenuItem value={p.name} key={p.name} sx={BASE_SX}>
                                        <div style={{ display: 'flex' }}>
                                            <span
                                                style={{ background: gradient }}
                                                className={style.palletPreview}
                                            ></span>
                                            <span className={style.palletName}>{p.label}</span>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                {/* Function */}
                <MathFunctionSlider
                    setSelectedFunction={setSelectedFunction}
                    selectedFunction={selectedFunction}
                    disabled={disabled}
                />
            </Box>
        </div>
    );
};

export default ColorBySelector;
