import React from 'react';
import style from './SkeletonTreemapLegend.module.scss';
import Skeleton from '@mui/material/Skeleton';
const numberOfFakeEntries = 15;

const SkeletonTreemapLegend = () => {
    const fakeEntries = new Array(numberOfFakeEntries)
        .fill(null)
        .map((_, i) => (
            <Skeleton key={i} animation="wave" className={style.entry} sx={{ backgroundColor: '#F4F4F4' }} />
        ));

    return (
        <div className={style.legendWrapper}>
            <Skeleton animation="wave" className={style.entry} sx={{ backgroundColor: '#F4F4F4' }} />
            <div style={{ padding: '0.3em 0 0 0' }}>{fakeEntries}</div>
        </div>
    );
};

export default SkeletonTreemapLegend;
