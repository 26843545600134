import {
    ExplorerConfig,
    MosaicRecord,
    LeafMosaicRecord,
    WeightedMosaicRecord,
    WeightedLeafMosaicRecord,
    isLeafMosaicRecord,
    isMosaicRecord,
    isWeightedLeafMosaicRecord,
    ExplorerLayout,
    isWeightedMosaicRecord,
    WeightedMosaicRecordWithParent,
    WeightedLeafMosaicRecordWithParent,
    isWeightedLeafMosaicRecordWithParent,
    isWeightedMosaicRecordWithParent,
} from '../types';
import { functions, undefinedColor } from './consts';
import getColors from './getColor';

/**
 * Adding weights to each cluster & group
 *
 * @param records
 * @param attributeName
 * @param fn
 * @returns records with weight
 */
const addWeights = (
    records: (MosaicRecord | LeafMosaicRecord)[],
    attributeName: string,
    fn: string,
): (WeightedMosaicRecord | WeightedLeafMosaicRecord)[] => {
    return records.map((r) => {
        if (isLeafMosaicRecord(r)) {
            const weight = +functions[fn](r.cluster.dynamicAttributes[attributeName]);
            return { ...r, weight };
        }

        const newGroups = addWeights(r.groups, attributeName, fn);
        return { ...r, groups: newGroups, weight: newGroups.reduce((p, c) => p + c.weight, 0) };
    });
};

/**
 * Generate the function that will color each cluster
 *
 * @param attribute
 * @param fnName
 * @param palette
 * @param min
 * @param max
 * @returns color record function
 */
const generateColorRecordsFn = (attribute: string, fnName: string, palette: string, min: number, max: number) => {
    const fn = functions[fnName];
    const rangeA = fn(max - min);

    /**
     * Color records
     * @param record
     * @returns
     */
    const colorRecords = (record: MosaicRecord | LeafMosaicRecord): MosaicRecord | LeafMosaicRecord => {
        if (isMosaicRecord(record)) {
            record.groups = record.groups.map(colorRecords);
        } else if (isLeafMosaicRecord(record)) {
            const val = +record.cluster.dynamicAttributes[attribute];
            let color;
            if (val === undefined) {
                color = undefinedColor;
            } else {
                const colors = getColors(palette);
                const range = rangeA / (colors.length - 1);
                const colorIndex = Math.round(fn(val - min) / range);

                color = colors[colorIndex];
            }

            // TODO: Improve
            (record as any).color = `#${color}`;
        }

        return record;
    };

    return colorRecords;
};

/**
 * Sort records (if needed) based on the selected layout
 *
 * @param records
 * @param selectedLayout
 * @returns
 */
const sortRecords = (
    records: (WeightedMosaicRecord | WeightedLeafMosaicRecord)[],
    selectedLayout: ExplorerLayout,
): (WeightedMosaicRecord | WeightedLeafMosaicRecord)[] => {
    if (selectedLayout !== ExplorerLayout.rectangular) {
        return records;
    }

    // The layout is rectangular - will sort from lowest to highest weight
    return records
        .map((r) => {
            if (isWeightedLeafMosaicRecord(r)) {
                return r;
            }

            const newGroups = r.groups.sort((a, b) => b.weight - a.weight);
            return { ...r, groups: newGroups };
        })
        .sort((a, b) => b.weight - a.weight);
};

/**
 * Linking parents to the clusters
 *
 * @param records
 * @returns
 */
const addParentLink = (
    records: (WeightedMosaicRecord | WeightedLeafMosaicRecord)[],
): (WeightedMosaicRecordWithParent | WeightedLeafMosaicRecordWithParent)[] => {
    return records.map((child) => {
        if (isWeightedMosaicRecord(child)) {
            for (const subChild of child.groups) {
                (subChild as WeightedMosaicRecordWithParent).parent = child;
                addParentLink([subChild]);
            }
        }

        return child;
    });
};

/**
 * Deleting the detailPaneItems provided by the API to simulate the new API
 *
 * Note: Will be removed once the new API will roll out
 * @param records
 * @returns
 */
const deleteDetailPaneItems = (
    records: (WeightedMosaicRecordWithParent | WeightedLeafMosaicRecordWithParent)[],
): (WeightedMosaicRecordWithParent | WeightedLeafMosaicRecordWithParent)[] => {
    return records.map((child) => {
        if (isWeightedLeafMosaicRecordWithParent(child)) {
            delete child.cluster.detailPaneItems;
        } else if (isWeightedMosaicRecordWithParent(child)) {
            child.groups = deleteDetailPaneItems(child.groups);
        }
        return child;
    });
};

/**
 * Create Formtree model from the given data and config
 *
 * @param records
 * @param config
 * @param minValue
 * @param maxValue
 * @param selectedLayout
 * @returns
 */
const generateModel = (
    records: MosaicRecord[],
    config: ExplorerConfig,
    minValue: number,
    maxValue: number,
    selectedLayout: ExplorerLayout,
) => {
    // The records are already grouped, we just need to color and calculate the weight
    const colorRecord = generateColorRecordsFn(
        config.colorBy.attribute,
        config.colorBy.fn,
        config.colorBy.palette,
        minValue,
        maxValue,
    );

    // Coloring
    const colored = records.map(colorRecord);

    // Adding weights - "Light weight, baby! Ronnie Coleman
    const weighted = addWeights(colored, config.sizeBy.attribute, config.sizeBy.fn);

    // Sort (by the selected layout)
    const sorted = sortRecords(weighted, selectedLayout);

    // Adding a link to the parent - the 5th commandment
    const withParents = addParentLink(sorted);

    // Delete the detailPaneItems to simulate the new API after the old one will be deprecated
    const withOutDetailPaneItems = deleteDetailPaneItems(withParents);

    return withOutDetailPaneItems;
};

export default generateModel;
