export const JWT_TOKEN_COOKIE_NAME = 'token';

export const routes = {
    login: '/login',
    noViews: '/no-views',
};

export const basenameForRoutes = process.env.REACT_APP_BASENAME || '';

export const FILTERS_URL_PARAM_NAME = 'encodedFilters';
export const VIEW_ID_URL_PARAM_NAME = 'viewId';
export const REVISION_URL_PARAM_NAME = 'revision';
export const LOGICAL_OPERATOR_URL_PARAM_NAME = 'operator';
export const SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME = 'minClusterSize';
