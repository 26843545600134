export enum FieldType {
    continuous = 'continuous',
    discrete = 'discrete',
}

export type Formatters = 'numberToStringWithUnit' | 'identity';
export type Preprocessors = 'stringToNumber';
export interface Field {
    type: FieldType;
    formatter: Formatters;
    ordering?: string[];
    preprocessing?: Preprocessors;
    unit?: string;
    decimals?: number;
}

export interface ExplorerConfig {
    groupBy: string[];
    sizeBy: {
        attribute: string;
        fn: string; // TODO change to enum
    };
    colorBy: {
        attribute: string;
        fn: string; // TODO change to enum
        palette: string; // TODO change to enum
    };
}

export enum ExplorerLayout {
    polygonal = 'polygonal',
    rectangular = 'rectangular',
}

export interface CustomerView {
    id: string;
    name: string;
    logo: string;
}

export interface SignInUser {
    email: string;
    password: string;
}

export interface SignInResponse {
    access_token: string;
}

export interface DecodedJWT {
    exp: number;
    sub: string;
}

export enum SemanticType {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    ARRAY = 'ARRAY',
    BOOL = 'BOOL',
    LONG_STRING = 'LONG_STRING',
}

export enum FieldDisplay {
    PERCENTAGE = 'PERCENTAGE',
}

export interface FieldsMetadata {
    [fieldName: string]: {
        semantic_type: SemanticType;
        display?: FieldDisplay;
        display_name?: string;
    };
}

export interface AppliedFilter {
    field: string;
    operator: string;
    value: string | number | string[];
    should_intersect_on_multiple_values?: boolean;
}

export enum LogicalOperator {
    intersection = 'intersection',
    union = 'union',
}

export interface AllowedFilter {
    field: string;
    min?: number;
    max?: number;
}

export interface ClusterFieldNamesMapping {
    [name: string]: string;
}

export interface MosaicMetadata {
    dataset: {
        fields_metadata: FieldsMetadata;
        size_by_fields: string[];
        group_by_fields: string[];
        color_by_fields: string[];
        filter_fields: AllowedFilter[];
        search_fields: string[];
        cluster_field_names?: ClusterFieldNamesMapping;
    };

    view: {
        color_by: {
            attributes: string[];
            fn: string; // TODO: Change to enum
            palette: string; // TODO: Change to enum
        };
        size_by: {
            attributes: string[];
            fn: string; // TODO: Change to enum
        };
        group_by: {
            attributes: string[];
            levels: 1;
        };
        filters: AppliedFilter[];
        filters_logical_operator?: LogicalOperator;
    };
}

export interface MosaicRecord {
    label: string;
    groups: (MosaicRecord | LeafMosaicRecord)[];
}

export enum RecordHeaderType {
    KeyValue = 'key-value',
}

export interface RecordHeader {
    type: RecordHeaderType;
    values: {
        key: string;
        value: string | number;
    };
}

export interface DetailPaneItem {
    _id: string;
    [key: string]: string;
}

export interface LeafMosaicRecord extends Omit<MosaicRecord, 'groups'> {
    cluster: {
        dynamicAttributes: { [key: string]: string | number };
        headers: RecordHeader[];
        detailPaneItems?: DetailPaneItem[];
        itemsCount?: number;
        detailPaneItemIds: string[];
        leftoverItemIds: string[];
    };
}

export interface WeightedMosaicRecord extends MosaicRecord {
    weight: number;
    groups: (WeightedMosaicRecord | WeightedLeafMosaicRecord)[];
}

export interface WeightedLeafMosaicRecord extends LeafMosaicRecord {
    weight: number;
}

export interface WeightedMosaicRecordWithParent extends WeightedMosaicRecord {
    parent?: WeightedMosaicRecordWithParent;
}

export interface WeightedLeafMosaicRecordWithParent extends WeightedLeafMosaicRecord {
    parent?: WeightedMosaicRecordWithParent;
}

export interface FilterOperators {
    [operatorName: string]: {
        semantic_types: SemanticType[];
    };
}

export function isLeafMosaicRecord(object: any): object is LeafMosaicRecord {
    return 'cluster' in object && object.cluster;
}

export function isWeightedLeafMosaicRecord(object: any): object is WeightedLeafMosaicRecord {
    return 'cluster' in object && object.cluster && 'weight' in object;
}

export function isWeightedMosaicRecord(object: any): object is WeightedMosaicRecord {
    return 'groups' in object && object.groups && Array.from(object.groups) && 'weight' in object;
}

export function isMosaicRecord(object: any): object is MosaicRecord {
    return 'groups' in object && object.groups && Array.from(object.groups);
}

export function isWeightedMosaicRecordWithParent(object: any): object is WeightedMosaicRecordWithParent {
    return 'groups' in object && object.groups && Array.from(object.groups) && 'weight' in object;
}

export function isWeightedLeafMosaicRecordWithParent(object: any): object is WeightedLeafMosaicRecordWithParent {
    return 'cluster' in object && object.cluster && 'weight' in object;
}

export enum SideMenuOpenOption {
    Config = 'Config',
    DetailsPane = 'DetailsPane',
}

export interface UserDetails {
    name: string;
    email: string;
    user_org: string;
}

export interface FiltersClusterHierarchy {
    [key: string]: string;
    cluster: string;
}

export interface ClusterItemsResponse {
    items: DetailPaneItem[];
}

export interface MappedAllowedFilters {
    [field: string]: AllowedFilter;
}

export type OperatorsPerSemanticType = Record<SemanticType, string[]>;

export interface AutocompleteResponse {
    values: [{ value: string }];
}

export interface MinClusterSizeResponse {
    min: number;
    max?: number;
    default: number;
}

export interface ClustersResponse {
    clusters: MosaicRecord[];
    min_cluster_size?: MinClusterSizeResponse;
}
