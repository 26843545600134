import React, { useCallback, useContext } from 'react';
import style from './Topbar.module.scss';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomerView } from '../../../types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { BootstrapInput, SideMenuXS, ViewDisplayXS } from './calculatedStyles';
import Searchbar from './Searchbar';
import UserAvatarWrapper from './UserAvatarWrapper';
import FiltersView from './FiltersView';
import { MosaicStoreContext } from '../../../stores/MosaicStore';
import TopbarSkeleton from './TopbarSkeleton';

interface TopbarProps {
    setCurrentView: (view: CustomerView) => void;
    onSearch: (keyword: string) => void;
}

const Topbar = (props: TopbarProps) => {
    const { setCurrentView, onSearch } = props;
    const mosaicStore = useContext(MosaicStoreContext);

    const handleChange = useCallback(
        (event: SelectChangeEvent) => {
            const viewId = event.target.value as string;
            const foundView = (mosaicStore?.availableViews || []).find((v) => v.id === viewId);

            if (!foundView) {
                console.warn('Could not find the selected view');
                return;
            }
            setCurrentView(foundView);
        },
        [setCurrentView, mosaicStore],
    );

    if (!mosaicStore?.currentView || !mosaicStore?.availableViews) {
        return <TopbarSkeleton />;
    }

    return (
        <Box className={style.topbar}>
            <Box sx={ViewDisplayXS}>
                <div style={{ backgroundImage: `url(${mosaicStore.currentView.logo})` }} className={style.logo} />
                <Select
                    value={mosaicStore.currentView.id}
                    variant="standard"
                    autoWidth
                    input={<BootstrapInput />}
                    onChange={handleChange}
                >
                    {mosaicStore.availableViews.map((view) => (
                        <MenuItem key={view.id} value={view.id}>
                            {view.name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={SideMenuXS}>
                <FiltersView />
                <Searchbar onSearch={onSearch} disabled={mosaicStore?.isDataLoading} />
                <Divider orientation="vertical" flexItem />
                <Box className={style.avatarWrapper}>
                    <UserAvatarWrapper />
                </Box>
            </Box>
        </Box>
    );
};

export default Topbar;
