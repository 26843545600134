import React from 'react';
import { useCookies } from 'react-cookie';
import { useJwt } from 'react-jwt';
import { Navigate, Outlet } from 'react-router-dom';
import { JWT_TOKEN_COOKIE_NAME, routes } from './consts';

const PrivateRoute = () => {
    const [cookies] = useCookies([JWT_TOKEN_COOKIE_NAME]);
    const token = cookies[JWT_TOKEN_COOKIE_NAME];
    const { isExpired } = useJwt(token);

    const isAuth = !isExpired;

    return isAuth ? <Outlet /> : <Navigate to={routes.login} />;
};

export default PrivateRoute;
