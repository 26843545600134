import React from 'react';
import { ExplorerLayout } from '../../../types';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import Box from '@mui/material/Box';
import style from './LayoutSelector.module.scss';
import GroupTitle from './GroupTitle';

interface LayoutSelectorProps {
    selectedLayout: ExplorerLayout;
    disabled: boolean;
    setSelectedLayout: (layout: ExplorerLayout) => void;
}
const LayoutSelector = (props: LayoutSelectorProps) => {
    const { selectedLayout, disabled, setSelectedLayout } = props;
    const { t } = useTranslation();

    return (
        <div className={style.layoutWrapper}>
            <GroupTitle>{t('explorerConfigPanel.configHeaders.layout')}</GroupTitle>
            <Box>
                <ToggleButtonGroup
                    value={selectedLayout}
                    disabled={disabled}
                    exclusive
                    onChange={(_e, newLayout: string | undefined) => {
                        if (newLayout) {
                            setSelectedLayout(newLayout as ExplorerLayout);
                        }
                    }}
                    aria-label="layout"
                    color="primary"
                    size="small"
                >
                    <ToggleButton value={ExplorerLayout.polygonal}>
                        <PentagonOutlinedIcon sx={{ fontSize: '18px' }} />
                        <Typography className={style.layoutName}>
                            {t(`explorerConfigPanel.layouts.${ExplorerLayout.polygonal}`)}
                        </Typography>
                    </ToggleButton>
                    <ToggleButton value={ExplorerLayout.rectangular}>
                        <DashboardOutlinedIcon sx={{ fontSize: '18px' }} />
                        <Typography className={style.layoutName}>
                            {t(`explorerConfigPanel.layouts.${ExplorerLayout.rectangular}`)}
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </div>
    );
};

export default LayoutSelector;
