import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import style from './UserAvatar.module.scss';
import { routes } from '../../consts';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string, sx?: SxProps) {
    const splitName = name.split(' ');
    let nameToDisplay;
    if (splitName.length < 2) {
        nameToDisplay = `${splitName[0][0]}`;
    } else {
        nameToDisplay = `${splitName[0][0]}${splitName[splitName.length - 1][0]}`;
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
            ...(sx || {}),
        },
        children: nameToDisplay,
    };
}

interface UserAvatarProps {
    userName: string;
    email: string;
    sx?: SxProps;
}

const UserAvatar = (props: UserAvatarProps) => {
    const { userName, email, sx } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick} size="small">
                <Avatar {...stringAvatar(userName, sx)} />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{ style: { minWidth: 270 } }}>
                <Box sx={{ display: 'flex', p: 1 }}>
                    <Avatar {...stringAvatar(userName, sx)} />
                    <Box sx={{ pl: 1 }}>
                        <Typography component="div" className={style.name}>
                            {userName}
                        </Typography>
                        <Typography gutterBottom component="div" className={style.email}>
                            {email}
                        </Typography>
                    </Box>
                </Box>
                <Divider />

                <MenuItem component={Link} to={routes.login} className={style.menuItem}>
                    <LogoutIcon sx={{ mr: 1 }} />
                    {t('userAvatar.menu.signOut')}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserAvatar;
