import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const ItemCardSkeleton = () => {
    return (
        <Card variant="outlined" sx={{ m: 1 }}>
            <CardHeader
                disableTypography
                title={<Skeleton animation="wave" variant="text" sx={{ backgroundColor: '#F4F4F4' }} />}
            ></CardHeader>
            <CardContent sx={{ pt: 0 }}>
                <Skeleton animation="wave" variant="text" sx={{ width: 150, backgroundColor: '#F4F4F4' }} />
                <Skeleton animation="wave" variant="text" sx={{ width: 200, backgroundColor: '#F4F4F4' }} />
                <Skeleton animation="wave" variant="text" sx={{ width: 100, backgroundColor: '#F4F4F4' }} />
                <Skeleton animation="wave" variant="text" sx={{ width: 150, backgroundColor: '#F4F4F4' }} />
            </CardContent>
        </Card>
    );
};

export default ItemCardSkeleton;
