import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AllowedFilter, FieldsMetadata, OperatorsPerSemanticType, SemanticType } from '../../../../../types';
import FilterValue from './FilterValue';
import CloseIcon from '@mui/icons-material/Close';
import LogicalOperatorSelector from './LogicalOperatorSelector';
import TypeIcon from './TypeIcon';
import Grid from '@mui/material/Grid';
import style from './FilterRow.module.scss';
import { generateFilterDisplayName } from './helpers';
interface FilterRowProps {
    i: number;
    error: boolean;
    field: string;
    operator: string;
    allowedFilters: AllowedFilter[];
    operatorsBySemanticType: OperatorsPerSemanticType | undefined;
    fieldsMetadata: FieldsMetadata | undefined;
    errorMessage: string | undefined;
    onDelete: () => void;
    setFieldValue: (field: string, value: any) => void;
}

const FilterRow: React.FC<FilterRowProps> = (props) => {
    const {
        i,
        field,
        operator,
        allowedFilters,
        operatorsBySemanticType,
        fieldsMetadata,
        error,
        errorMessage,
        onDelete,
        setFieldValue,
    } = props;
    const { t } = useTranslation();
    const type: SemanticType | undefined = fieldsMetadata && fieldsMetadata[field]?.semantic_type;
    const operators = operatorsBySemanticType && type ? operatorsBySemanticType[type] : [];

    return (
        <>
            <Grid item xs={1.5}>
                <LogicalOperatorSelector i={i} />
            </Grid>
            <Grid item xs={2.5}>
                {/* Field */}
                <Field as={Select} name={`filters.${i}.field`} fullWidth>
                    <MenuItem value="">
                        <div className={style.fieldSelectRow}>
                            <em>{t('filters.fieldPlaceholder')}</em>
                        </div>
                    </MenuItem>

                    {allowedFilters.map((f) => (
                        <MenuItem key={f.field} value={f.field}>
                            <div className={style.fieldSelectRow}>
                                <TypeIcon semanticType={fieldsMetadata && fieldsMetadata[f.field]?.semantic_type} />
                                <span className={style.selectValue}>
                                    {generateFilterDisplayName(f.field, fieldsMetadata)}
                                </span>
                            </div>
                        </MenuItem>
                    ))}
                </Field>
            </Grid>
            <Grid item xs={2.5}>
                {/* Operator */}
                <Field as={Select} name={`filters.${i}.operator`} fullWidth>
                    <MenuItem value="">
                        <em>{t('filters.operatorPlaceholder')}</em>
                    </MenuItem>
                    {operators.map((o) => (
                        <MenuItem key={o} value={o}>
                            {t(`filters.operatorsPerSemanticType.${type}.${o}`, {
                                defaultValue: t(`filters.operators.${o}`, { defaultValue: o }),
                            })}
                        </MenuItem>
                    ))}
                </Field>
            </Grid>
            <Grid item xs={5}>
                {/* Value */}
                <Field
                    as={FilterValue}
                    semanticType={(fieldsMetadata || {})[field]?.semantic_type}
                    name={`filters.${i}.value`}
                    error={error}
                    errorMessage={errorMessage}
                    setFieldValue={setFieldValue}
                    operator={operator}
                    field={field}
                />
            </Grid>
            <Grid item xs={0.5} className={style.deleteButtonWrapper}>
                {/* Delete filter */}
                <IconButton aria-label="delete" onClick={onDelete} size="small">
                    <CloseIcon sx={{ fontSize: 20 }} />
                </IconButton>
            </Grid>
        </>
    );
};

export default FilterRow;
