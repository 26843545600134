import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import generateModel from '../../../helper/generateModel';
import {
    ExplorerConfig,
    ExplorerLayout,
    MosaicMetadata,
    MosaicRecord,
    WeightedLeafMosaicRecordWithParent,
} from '../../../types';
import { defaultCarrotSearchFoamTreeParams } from './consts';
import style from './Explorer.module.scss';
import { generateLastRenderInfo, handleSearch } from './helpers';
import { LoadingExplorerAnimationPortal } from './LoadingExplorerAnimation';

interface ExplorerProps {
    data: MosaicRecord[] | undefined;
    metadata: MosaicMetadata | undefined;
    config: ExplorerConfig | undefined;
    searchKeyword: string | undefined;
    minValue: number;
    maxValue: number;
    selectedLayout: ExplorerLayout;
    dataLoading: boolean;
    isSideMenuOpen: boolean;
    showDetailsPanel: (item: WeightedLeafMosaicRecordWithParent) => void;
    clearSearch: () => void;
}

const Explorer = observer((props: ExplorerProps) => {
    const foamtreeRef = useRef<any | null>(null);
    const loaderRef = useRef<any | null>(null);

    const {
        data,
        config,
        metadata,
        searchKeyword,
        selectedLayout,
        minValue,
        maxValue,
        dataLoading,
        isSideMenuOpen,
        showDetailsPanel,
        clearSearch,
    } = props;

    const [lastSearchKeyword, setLastSearchKeyword] = useState(searchKeyword);
    const [lastRenderInfo, setLastRenderInfo] = useState<string>();
    const [prevDataLoading, setPrevDataLoading] = useState<boolean>(dataLoading);
    const [shouldShowLoader, setShouldShowLoader] = useState(false);

    const showLoader = () => setShouldShowLoader(true);
    const hideLoader = () => setShouldShowLoader(false);

    // Initial mounting effect
    useEffect(() => {
        foamtreeRef.current = new CarrotSearchFoamTree(defaultCarrotSearchFoamTreeParams(clearSearch));
        loaderRef.current = CarrotSearchFoamTree.loader(
            foamtreeRef.current,
            '<div id="treemapLoader" style="height: 100%;width: 100%;"></div>',
        );

        // Resize FoamTree on orientation change
        window.addEventListener('orientationchange', foamtreeRef.current.resize);

        let timeout: number;
        // Resize on window size changes
        window.addEventListener('resize', () => {
            window.clearTimeout(timeout);
            timeout = window.setTimeout(function () {
                foamtreeRef.current.set('pixelRatio', window.devicePixelRatio || 1);
                foamtreeRef.current.resize();
            }, 300);
        });

        foamtreeRef.current.on('groupClick', function (e: any) {
            if (!e.group || !e.group.cluster) {
                e.preventDefault();
            }
        });

        foamtreeRef.current.on('groupSelectionChanging', function (e: any) {
            if (!e.group || !e.group.cluster) {
                showDetailsPanel(e.group);
            }

            if (e.selected) {
                showDetailsPanel(e.group);
            }
        });

        foamtreeRef.current.set('dataObject', {});
        loaderRef.current.started();
        showLoader();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update data/config effect
    useEffect(() => {
        if (!foamtreeRef.current || !loaderRef.current) {
            return;
        }

        if (dataLoading) {
            // We're loading data, resetting the view and showing the loader
            foamtreeRef.current.set('dataObject', {});
            loaderRef.current.started();
            showLoader();
            setPrevDataLoading(dataLoading);
        } else if (data && config && metadata) {
            // The data was loaded! - we're verging the data was changed, if the data was changed, re-render
            generateLastRenderInfo(data, config, metadata).then((renderInfo) => {
                // Displaying the data
                if (renderInfo !== lastRenderInfo || prevDataLoading) {
                    setLastRenderInfo(renderInfo);

                    foamtreeRef.current.set('dataObject', {});
                    loaderRef.current.complete({
                        groups: generateModel(data, config, minValue, maxValue, selectedLayout),
                    });
                    hideLoader();
                }

                setPrevDataLoading(dataLoading);

                if (lastSearchKeyword !== searchKeyword) {
                    setLastSearchKeyword(searchKeyword);
                    // Handle search
                    handleSearch(foamtreeRef, data, metadata, searchKeyword);
                }
            });
        }
    }, [
        data,
        config,
        metadata,
        minValue,
        maxValue,
        dataLoading,
        selectedLayout,
        searchKeyword,
        lastRenderInfo,
        prevDataLoading,
        lastSearchKeyword,
    ]);

    // Update layout effect
    useEffect(() => {
        if (!foamtreeRef.current || !loaderRef.current || !data || !config || !metadata) {
            return;
        }

        switch (selectedLayout) {
            case ExplorerLayout.rectangular:
                foamtreeRef.current.set({
                    descriptionGroupSize: 0.0,
                    descriptionGroupMinHeight: 18,
                    layout: 'squarified',
                });
                break;
            case ExplorerLayout.polygonal:
                foamtreeRef.current.set({
                    descriptionGroupMinHeight: 30,
                    descriptionGroupSize: 0.15,
                    //groupLabelHorizontalPadding: 0.0,
                    //groupLabelVerticalPadding: 0.0,
                    groupLabelColorThreshold: 0.6,
                    groupLabelLineHeight: 1,
                    groupLabelMaxTotalHeight: 1,
                    //relaxationQualityThreshold: 3.2,
                    layoutByWeightOrder: false,
                    //groupBorderRadius: 0.5,
                    //groupLabelFontFamily: “Tahoma”,
                    //groupLabelMinFontSize: 28,
                    rainbowLightnessShift: 19,
                    //groupFillGradientCenterSaturationShift: 100,
                    //rainbowLightnessShiftCenter: 0.3,
                    //groupLabelLineHeight: 1,
                    layout: 'relaxed',
                });
                break;
        }

        if (selectedLayout === ExplorerLayout.rectangular) {
            foamtreeRef.current.set('dataObject', {
                groups: generateModel(data, config, minValue, maxValue, selectedLayout),
            });
            hideLoader();
        } else {
            loaderRef.current.started();

            setTimeout(function () {
                loaderRef.current.complete({
                    groups: generateModel(data, config, minValue, maxValue, selectedLayout),
                });
                hideLoader();
            }, 20);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLayout]);

    // Opening/closing side menu effect
    useEffect(() => {
        foamtreeRef.current.set('pixelRatio', window.devicePixelRatio || 1);
        foamtreeRef.current.resize();
    }, [isSideMenuOpen]);

    return (
        <>
            <div id="container" className={classNames(style.container, { [style.containerOpenMenu]: isSideMenuOpen })}>
                <div id="visualization" className={style.visualization}></div>
            </div>
            {shouldShowLoader && <LoadingExplorerAnimationPortal elementId="treemapLoader" />}
        </>
    );
});

export default Explorer;
