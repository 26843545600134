import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { SideMenuXS, ViewDisplayXS } from './calculatedStyles';
import FiltersView from './FiltersView';
import Searchbar from './Searchbar';
import UserAvatarWrapper from './UserAvatarWrapper';
import style from './Topbar.module.scss';

const TopbarSkeleton = () => {
    return (
        <Box className={style.topbar}>
            <Box sx={ViewDisplayXS}>
                <Skeleton
                    variant="rectangular"
                    width={60}
                    height={60}
                    sx={{ marginRight: 1, backgroundColor: '#F4F4F4' }}
                    animation="wave"
                />
                <Skeleton
                    variant="rectangular"
                    height={45}
                    width={150}
                    sx={{ marginTop: 1, backgroundColor: '#F4F4F4' }}
                    animation="wave"
                />
            </Box>
            <Box sx={SideMenuXS}>
                <FiltersView forceLock />
                <Searchbar onSearch={() => {}} disabled />
                <Divider orientation="vertical" flexItem />
                <Box className={style.avatarWrapper}>
                    <UserAvatarWrapper />
                </Box>
            </Box>
        </Box>
    );
};

export default TopbarSkeleton;
