import { ExplorerLayout } from "../../types";

export const title = 'View settings';

export const configHeaders = {
    groupBy: 'Group By',
    sizeBy: 'Size By',
    colorBy: 'Color By',
    layout: 'Layout',
};

export const layouts = {
    [ExplorerLayout.polygonal]: 'Polygonal',
    [ExplorerLayout.rectangular]: 'Rectangular'

}

export const noneValue = 'None';
