import { DetailPaneItem, FiltersClusterHierarchy, WeightedLeafMosaicRecordWithParent } from '../../../../types';

const POSSIBLE_TITLE_KEYS_BY_ORDER = ['subject', 'title', 'ai summary', 'id'];
const POSSIBLE_LINK_KEYS_BY_ORDER = ['url', 'link'];

/**
 * Strip percentage suffix
 *
 * @param label
 * @returns
 */
export const stripPercentage = (label: string) => {
    if (label.endsWith('%)')) {
        return label.slice(0, label.lastIndexOf('(')).trim();
    }
    return label;
};

/**
 * Generate the filters cluster hierarch using the given item and it's entire family tree
 *
 * Starting traversal from the leaf until the root, each level uses the selected group by
 * to extract the field name
 *
 * @param detailsPanelItem
 * @param selectedGroupByFields
 * @returns
 */
export const generateFiltersClusterHierarchy = (
    detailsPanelItem: WeightedLeafMosaicRecordWithParent,
    selectedGroupByFields: string[],
): FiltersClusterHierarchy => {
    const filtersClusterHierarchy: FiltersClusterHierarchy = {
        cluster: detailsPanelItem.label,
    };

    let currentParent = detailsPanelItem.parent;
    let parentCount = 0;
    while (currentParent) {
        filtersClusterHierarchy[selectedGroupByFields[selectedGroupByFields.length - 1 - parentCount]] =
            currentParent.label;

        parentCount++;
        currentParent = currentParent.parent;
    }

    return filtersClusterHierarchy;
};

/**
 * Convert an item to keys with lowercase
 *
 * @param item
 * @returns
 */
export const convertItemToLowerCase = (item: { [key: string]: any }) => {
    return Object.fromEntries(Object.entries(item).map(([key, value]) => [key.toLowerCase(), value]));
};

/**
 * Extract the title from a given item
 *
 * @param item
 * @returns
 */
export const extractItemTitle = (item: DetailPaneItem) => {
    const loweredCaseItem = convertItemToLowerCase(item);

    for (const possibleKey of POSSIBLE_TITLE_KEYS_BY_ORDER) {
        if (loweredCaseItem[possibleKey]) {
            return loweredCaseItem[possibleKey];
        }
    }

    // Not found any item title
    return undefined;
};

/**
 * Extract a link from the given item
 *
 * @param item
 * @returns
 */
export const extractItemLink = (item: DetailPaneItem) => {
    const loweredCaseItem = convertItemToLowerCase(item);

    for (const possibleKey of POSSIBLE_LINK_KEYS_BY_ORDER) {
        if (loweredCaseItem[possibleKey]) {
            return loweredCaseItem[possibleKey];
        }
    }

    // Not found any item title
    return undefined;
};
