import React, { useEffect, useState } from 'react';
import { DetailPaneItem } from '../../../../types';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DetailValue from './DetailValue';
import capitalizeEachWord from '../../../../helper/capitalizeEachWord';
import style from './ItemCard.module.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import LightTooltip from '../../../common/LightTooltip';
import { extractItemLink, extractItemTitle } from './helper';

interface ItemCardProps {
    item: DetailPaneItem;
    allOpen: boolean;
}

const ItemCard: React.FC<ItemCardProps> = (props) => {
    const { item, allOpen } = props;
    const [open, setOpen] = useState(allOpen);
    const title = extractItemTitle(item);
    const link = extractItemLink(item);

    useEffect(() => {
        setOpen(allOpen);
    }, [allOpen]);

    const toggleOpen = () => setOpen((prev) => !prev);
    return (
        <Card variant="outlined" sx={{ m: 1 }}>
            <CardHeader
                disableTypography
                title={
                    <div className={style.headerWrapper} onClick={toggleOpen}>
                        <IconButton
                            size="small"
                            sx={{
                                transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                                color: open ? 'neutralSelected.main' : 'neutral.main',
                                transition: (theme: Theme) =>
                                    theme.transitions.create(['transform', 'color'], {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                            }}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        <LightTooltip title={title} arrow placement="left">
                            <Typography className={style.title} sx={{ color: 'neutralSelected.main' }}>
                                {title}
                            </Typography>
                        </LightTooltip>
                        {link && (
                            <IconButton size="small" component={Link} href={link} target="_blank" rel="noreferrer">
                                <OpenInNewIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                        )}
                    </div>
                }
            ></CardHeader>
            <Collapse in={open}>
                <CardContent sx={{ pt: 0 }}>
                    <table>
                        <tbody>
                            {Object.entries(item).map(([key, value]) => {
                                // Not rendering the id
                                if (key === '_id') {
                                    return null;
                                }

                                const keyView = capitalizeEachWord(key);
                                return (
                                    <tr key={item._id + '-' + key}>
                                        <td className={style.keyTd}>
                                            <LightTooltip title={keyView} arrow placement="left">
                                                <Typography color="darkGray.main" className={style.key}>
                                                    {keyView}
                                                </Typography>
                                            </LightTooltip>
                                        </td>
                                        <td>
                                            <DetailValue value={value as string | undefined} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default ItemCard;
