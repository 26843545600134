import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FilterFormData } from '.';
import { FieldsMetadata, SemanticType } from '../../../../types';
import { AUTOCOMPLETE_OPERATORS_FOR_STRING, CUSTOM_OPERATORS_FN } from '../../filtersHelper';

interface FormObserverProps {
    values: FilterFormData;
    fieldsMetadata: FieldsMetadata | undefined;
    setValues: (values: React.SetStateAction<FilterFormData>) => void;
}

const FormObserver = (props: FormObserverProps) => {
    const { values, fieldsMetadata, setValues } = props;
    const [prevValues, setPrevValues] = useState(values);

    useEffect(() => {
        if (!isEqual(prevValues, values)) {
            let valuesToSet = values;
            if (prevValues.filters.length === values.filters.length) {
                valuesToSet = {
                    filters_logical_operator: values.filters_logical_operator,
                    filters: values.filters.map((f, i) => {
                        const prevF = prevValues.filters[i];
                        const semanticType: SemanticType | undefined =
                            fieldsMetadata && fieldsMetadata[f.field]?.semantic_type;

                        if (prevF.field !== f.field) {
                            // Changed the filter field - resetting the value and operator
                            f.value = '';
                            f.operator = '';
                        } else if (
                            // If string, checks if the new and prev operator aren't in the same "class" (if one is autocomplete and the other is not)
                            (semanticType === SemanticType.STRING &&
                                AUTOCOMPLETE_OPERATORS_FOR_STRING.has(prevF.operator) &&
                                !AUTOCOMPLETE_OPERATORS_FOR_STRING.has(f.operator)) ||
                            (!AUTOCOMPLETE_OPERATORS_FOR_STRING.has(prevF.operator) &&
                                AUTOCOMPLETE_OPERATORS_FOR_STRING.has(f.operator)) ||
                            // If the the new and prev operator aren't in the same "class" (if one is customer and the other is not)
                            (prevF.operator in CUSTOM_OPERATORS_FN && !(f.operator in CUSTOM_OPERATORS_FN)) ||
                            (!(prevF.operator in CUSTOM_OPERATORS_FN) && f.operator in CUSTOM_OPERATORS_FN)
                        ) {
                            // Reset value
                            f.value = '';
                        }

                        return f;
                    }),
                };
                if (!isEqual(valuesToSet, values)) {
                    setValues(valuesToSet);
                }
            }
            setPrevValues(valuesToSet);
        }
    }, [prevValues, values, fieldsMetadata, setValues]);

    return null;
};

export default FormObserver;
