import lodash from 'lodash';
import { isWeightedLeafMosaicRecord, WeightedLeafMosaicRecord, WeightedMosaicRecord } from '../types';

const searchInLeaf = (leaf: WeightedLeafMosaicRecord, searchFields: string[], searchKeyword: string) => {
    if (!leaf.cluster) {
        return false;
    }

    for (const searchField of searchFields) {
        const value = lodash.get(leaf, searchField);

        if (
            // If string
            (typeof value === 'string' && value.trim().toLowerCase().includes(searchKeyword)) ||
            // If number - convert to string
            (typeof value === 'number' && `${value}` === searchKeyword)
        ) {
            return true;
        }
    }

    return false;
};

const extractGroups = (child: WeightedMosaicRecord | WeightedLeafMosaicRecord, arr: WeightedLeafMosaicRecord[]) => {
    if (isWeightedLeafMosaicRecord(child)) {
        arr.push(child);

        return;
    }

    for (const subChild of child.groups) {
        extractGroups(subChild, arr);
    }
};

const searchInGroups = (
    searchKeyword: string,
    searchFields: string[],
    treeformDataRoot: WeightedMosaicRecord,
): WeightedLeafMosaicRecord[] => {
    const leafs: WeightedLeafMosaicRecord[] = [];
    for (const child of treeformDataRoot.groups) {
        extractGroups(child, leafs);
    }

    const foundLeafs = leafs.filter((l) => searchInLeaf(l, searchFields, searchKeyword));

    return foundLeafs;
};

export default searchInGroups;
