import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import api from '../../api';
import {
    ExplorerLayout,
    CustomerView,
    MosaicRecord,
    SideMenuOpenOption,
    WeightedLeafMosaicRecordWithParent,
    LogicalOperator,
} from '../../types';
import Explorer from './Explorer';
import { calculateMinMax, haveUndefined, handleStoreUpdate, countClusters } from './helpers';
import TreemapLegend from './TreemapLegend';
import { debounce } from 'lodash';
import { URLSearchParamsInit, useNavigate, useSearchParams } from 'react-router-dom';
import {
    FILTERS_URL_PARAM_NAME,
    LOGICAL_OPERATOR_URL_PARAM_NAME,
    REVISION_URL_PARAM_NAME,
    routes,
    SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME,
    VIEW_ID_URL_PARAM_NAME,
} from '../consts';
import style from './Dashboard.module.scss';
import eventLogger from '../../eventLogger';
import axios, { AxiosError } from 'axios';
import getFormatterName from '../../helper/getFormatterName';
import getField from '../../helper/getField';
import { observer } from 'mobx-react-lite';
import { MosaicStoreContext } from '../../stores/MosaicStore';
import { Buffer } from 'buffer';
import isEqual from 'react-fast-compare';
import Topbar from './Topbar';
import SideMenu from './SideMenu';
import { toJS } from 'mobx';
import { DEFAULT_FILTERS_LOGICAL_OPERATOR, MIN_NUMBER_OF_CLUSTERS_TO_SHOW } from '../../helper/consts';
import ExplorerBlocker from './ExplorerBlocker';
import { applyCustomOperators } from './filtersHelper';
import InsufficientDataBlock from './InsufficientDataBlock';

const Dashboard = observer(() => {
    const [data, setData] = useState<MosaicRecord[]>();
    const [detailsPanelItem, setDetailsPanelItem] = useState<WeightedLeafMosaicRecordWithParent>();
    const [searchKeyword, setSearchKeyword] = useState<string>();
    const [selectedLayout, setSelectedLayout] = useState(ExplorerLayout.polygonal);
    const [criticalError, setCriticalError] = useState<Error>();
    const [openOption, setOpenOption] = useState<SideMenuOpenOption>();
    const [showInsufficientData, setShowInsufficientData] = useState<boolean>(false);
    const mosaicStore = useContext(MosaicStoreContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const debouncedHandleStoreUpdate = useRef(debounce(handleStoreUpdate)).current;

    const loadMosaicData = useCallback(async () => {
        if (!mosaicStore || !mosaicStore.config?.groupBy || !mosaicStore.filters || !mosaicStore.currentView) {
            return;
        }

        try {
            mosaicStore?.setIsDataLoading(true);
            setDetailsPanelItem(undefined);
            setShowInsufficientData(false);
            setOpenOption(undefined);
            const dataFromApi = await api.getMosaicData(
                mosaicStore.currentView.id,
                mosaicStore.config.groupBy,
                applyCustomOperators(toJS(mosaicStore.filters)),
                searchParams.get(REVISION_URL_PARAM_NAME),
                mosaicStore?.filtersLogicalOperator || DEFAULT_FILTERS_LOGICAL_OPERATOR,
                !Boolean(mosaicStore?.minClusterSizeResponse),
                mosaicStore?.selectedMinClusterSize,
            );
            if (!dataFromApi) {
                return;
            }
            setData(dataFromApi.clusters);
            if (dataFromApi.min_cluster_size) {
                mosaicStore?.setMinClusterSizeResponse(dataFromApi.min_cluster_size);
            }
            mosaicStore?.setIsDataLoading(false);
            const count = countClusters(dataFromApi.clusters);
            setShowInsufficientData(count <= MIN_NUMBER_OF_CLUSTERS_TO_SHOW);
        } catch (e) {
            setCriticalError(e as Error);
        }
    }, [mosaicStore, searchParams]);

    const loadFiltersFromSearchParams = useCallback(
        (filtersFromParams: string | null) => {
            if (!filtersFromParams) {
                return;
            }

            try {
                const encodedFiltersFromParams = JSON.parse(Buffer.from(filtersFromParams, 'base64').toString());
                if (!isEqual(encodedFiltersFromParams, mosaicStore?.filters)) {
                    // We need to update the stored filters
                    mosaicStore?.setFilters(encodedFiltersFromParams);
                    loadMosaicData();
                }
            } catch (e) {
                console.error(e);
                // Resetting the searchParam
                searchParams.delete(FILTERS_URL_PARAM_NAME);
            }
        },
        [searchParams, mosaicStore, loadMosaicData],
    );

    const loadFiltersLogicalOperatorFromSearchParams = useCallback(
        (filtersLogicalOperatorFromParams: string | null) => {
            if (!filtersLogicalOperatorFromParams) {
                return;
            }

            try {
                const filtersLogicalOperator =
                    LogicalOperator[filtersLogicalOperatorFromParams as keyof typeof LogicalOperator];
                if (filtersLogicalOperator && filtersLogicalOperator !== mosaicStore?.filtersLogicalOperator) {
                    mosaicStore?.setFiltersLogicalOperator(filtersLogicalOperator);
                }
            } catch (e) {
                console.error(e);
                // Resetting the searchParam
                searchParams.delete(LOGICAL_OPERATOR_URL_PARAM_NAME);
            }
        },
        [searchParams, mosaicStore],
    );

    const loadSelectedMinClusterSizeFromSearchParams = useCallback(
        (selectedMinClusterSizeFromParams: string | null) => {
            if (!selectedMinClusterSizeFromParams) {
                return;
            }

            try {
                const selectedMinClusterSize = parseInt(selectedMinClusterSizeFromParams, 10);
                if (isNaN(selectedMinClusterSize)) {
                    throw new Error('Invalid selectedMinClusterSize in search params');
                }
                mosaicStore?.setSelectedMinClusterSize(selectedMinClusterSize);
            } catch (e) {
                console.error(e);
                // Resetting the searchParam
                searchParams.delete(SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME);
            }
        },
        [searchParams, mosaicStore],
    );

    useEffect(() => {
        debouncedHandleStoreUpdate(mosaicStore, searchParams, setSearchParams, loadMosaicData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mosaicStore,
        mosaicStore?.filters,
        mosaicStore?.currentView,
        mosaicStore?.selectedMinClusterSize,
        mosaicStore?.filtersLogicalOperator,
        setSearchParams,
        loadMosaicData,
        debouncedHandleStoreUpdate,
    ]);

    useEffect(() => {
        loadFiltersFromSearchParams(searchParams.get(FILTERS_URL_PARAM_NAME));
        loadFiltersLogicalOperatorFromSearchParams(searchParams.get(LOGICAL_OPERATOR_URL_PARAM_NAME));
        loadSelectedMinClusterSizeFromSearchParams(searchParams.get(SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME));
    }, [
        searchParams,
        loadFiltersFromSearchParams,
        loadFiltersLogicalOperatorFromSearchParams,
        loadSelectedMinClusterSizeFromSearchParams,
    ]);

    const showDetailsPanel = useCallback(
        (item: WeightedLeafMosaicRecordWithParent) => {
            const clusterName = item.cluster;
            const sizeByVal = item?.cluster.dynamicAttributes[mosaicStore?.config?.sizeBy.attribute || ''];
            const extraData = { Name: clusterName, Size: sizeByVal, 'View Name': mosaicStore?.currentView?.name };
            eventLogger.log('Clicked Cluster', extraData);
            setDetailsPanelItem(item);
            setOpenOption(SideMenuOpenOption.DetailsPane);
        },
        [mosaicStore],
    );

    const updateSelectedLayout = (layout: ExplorerLayout) => {
        eventLogger.log('Changed Layout', { Name: layout, 'View Name': mosaicStore?.currentView?.name });
        setSelectedLayout(layout);
    };

    const loadFilterOperators = useCallback(async () => {
        if (!mosaicStore) {
            return;
        }

        try {
            mosaicStore.startFilterOperatorsLoading();

            const data = await api.getFilterOperators();
            mosaicStore.loadFilterOperators(data);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const error = e as AxiosError;
                if (error.response?.status === 401) {
                    console.warn('Got 401 from the server, moving to login page');
                    navigate(routes.login);
                    return;
                }
            }

            setCriticalError(e as Error);
        }
    }, [mosaicStore, navigate]);

    const loadData = async () => {
        try {
            const views = await api.getCustomerView();

            if (views.length === 0) {
                console.warn('Your account does not have any views allocated - please contact support');
                navigate(routes.noViews);

                return;
            }
            mosaicStore!.setAvailableViews(views);

            // Load view from URL or the first view from API as the current view
            const firstView = views[0];
            const foundViewByUrl = views.find((v) => v.id === searchParams.get(VIEW_ID_URL_PARAM_NAME));
            const calculatedCurrentView = foundViewByUrl || firstView;
            loadViewData(calculatedCurrentView, true, false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const error = e as AxiosError;
                if (error.response?.status === 401) {
                    console.warn('Got 401 from the server, moving to login page');
                    navigate(routes.login);
                    return;
                }
            }

            setCriticalError(e as Error);
        }
    };

    const resetConfig = useCallback(() => {
        if (!mosaicStore) {
            return;
        }
        mosaicStore.resetConfig();
    }, [mosaicStore]);

    const loadViewData = async (view: CustomerView, withRevision: boolean, withSetSearchParams: boolean) => {
        try {
            if (!mosaicStore) {
                console.warn('No store was found');
                return;
            }

            mosaicStore.setIsDataLoading(true);
            mosaicStore.setCurrentView(view);

            if (!searchParams.has(SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME)) {
                mosaicStore.setSelectedMinClusterSize(undefined);
            }
            setShowInsufficientData(false);
            const revisionName = searchParams.get(REVISION_URL_PARAM_NAME);

            const metadataFromApi = await api.getMosaicMetadata(view.id);
            if (!metadataFromApi) {
                return;
            }

            const defaultGroupBy = [metadataFromApi.view.group_by.attributes[0]].filter(Boolean);
            const defaultFilters = metadataFromApi.view.filters;
            const logicalOperator = metadataFromApi.view.filters_logical_operator || DEFAULT_FILTERS_LOGICAL_OPERATOR;

            mosaicStore?.setConfig({
                groupBy: defaultGroupBy,
                sizeBy: {
                    attribute: metadataFromApi.view.size_by.attributes[0],
                    fn: metadataFromApi.view.size_by.fn,
                },
                colorBy: {
                    attribute: metadataFromApi.view.color_by.attributes[0],
                    fn: metadataFromApi.view.color_by.fn,
                    palette: metadataFromApi.view.color_by.palette,
                },
            });
            mosaicStore?.setMosaicMetadata(metadataFromApi);

            if (mosaicStore && mosaicStore.filtersLogicalOperator === undefined) {
                // We don't have filtersLogicalOperator, setting the default
                mosaicStore.setFiltersLogicalOperator(logicalOperator);
            }

            if (mosaicStore && mosaicStore.filters === undefined) {
                // We don't have filters, setting the default filters
                mosaicStore.setFilters(defaultFilters);
            }

            if (withSetSearchParams) {
                const str = JSON.stringify(mosaicStore.filters);

                const updatedSearchParams: URLSearchParamsInit = {
                    [FILTERS_URL_PARAM_NAME]: Buffer.from(str).toString('base64'),
                    [VIEW_ID_URL_PARAM_NAME]: view.id,
                    [LOGICAL_OPERATOR_URL_PARAM_NAME]:
                        mosaicStore.filtersLogicalOperator?.toString() || DEFAULT_FILTERS_LOGICAL_OPERATOR.toString(),
                };

                const minClusterSize = mosaicStore?.selectedMinClusterSize;
                if (minClusterSize !== undefined) {
                    updatedSearchParams[SELECTED_MIN_CLUSTER_SIZE_URL_PARAM_NAME] = minClusterSize.toString();
                }

                if (withRevision && revisionName) {
                    updatedSearchParams[REVISION_URL_PARAM_NAME] = revisionName;
                }

                setSearchParams(updatedSearchParams, { replace: false });
            }

            const filtersToUse = mosaicStore && mosaicStore.filters ? mosaicStore.filters : defaultFilters;
            const revision = withRevision ? revisionName : null;

            const dataFromApi = await api.getMosaicData(
                view.id,
                defaultGroupBy,
                applyCustomOperators(toJS(filtersToUse)),
                revision,
                mosaicStore?.filtersLogicalOperator || logicalOperator,
                !Boolean(mosaicStore?.minClusterSizeResponse),
                mosaicStore?.selectedMinClusterSize,
            );

            if (!dataFromApi) {
                return;
            }

            setData(dataFromApi.clusters);
            if (dataFromApi.min_cluster_size) {
                mosaicStore?.setMinClusterSizeResponse(dataFromApi.min_cluster_size);
            }
            mosaicStore?.setIsDataLoading(false);
            const count = countClusters(dataFromApi.clusters);
            setShowInsufficientData(count <= MIN_NUMBER_OF_CLUSTERS_TO_SHOW);
        } catch (e) {
            setCriticalError(e as Error);
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!mosaicStore?.filterOperatorsLoaded && !mosaicStore?.filterOperatorsLoaded) {
            loadFilterOperators();
        }
    }, [mosaicStore, loadFilterOperators]);

    const changeGroupBy = (index: number, value: string) => {
        mosaicStore?.setConfigWithFunction(
            (conf) => {
                if (conf === undefined) {
                    return conf;
                }

                const updatedGroupBy = [...conf.groupBy];
                if (value === '') {
                    updatedGroupBy.splice(index, 1);
                } else {
                    updatedGroupBy[index] = value;
                }

                eventLogger.log('Changed Group By', {
                    Levels: updatedGroupBy,
                    'View Name': mosaicStore?.currentView?.name,
                });

                return { ...conf, groupBy: updatedGroupBy };
            },
            // Reload the data AFTER we updated the store
            () => loadMosaicData(),
        );
    };

    const setSizeBy = (field: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Size By', { Attribute: field, 'View Name': mosaicStore?.currentView?.name });

            return { ...conf, sizeBy: { ...conf.sizeBy, attribute: field } };
        });
    };

    const setSelectedColorByField = (field: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Color By', {
                Attribute: field,
                Palette: conf.colorBy.palette,
                'View Name': mosaicStore?.currentView?.name,
            });

            return { ...conf, colorBy: { ...conf.colorBy, attribute: field } };
        });
    };

    const setSelectedColorFunction = (fnName: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Color By Function', {
                Function: fnName,
                Attribute: conf.colorBy.attribute,
                'View Name': mosaicStore?.currentView?.name,
            });

            return { ...conf, colorBy: { ...conf.colorBy, fn: fnName } };
        });
    };

    const setSelectedSizeFunction = (fnName: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Size By Function', {
                Function: fnName,
                Attribute: conf.sizeBy.attribute,
                'View Name': mosaicStore?.currentView?.name,
            });

            return { ...conf, sizeBy: { ...conf.sizeBy, fn: fnName } };
        });
    };

    const setSelectedPalette = (palette: string) => {
        mosaicStore?.setConfigWithFunction((conf) => {
            if (conf === undefined) {
                return conf;
            }

            eventLogger.log('Changed Color By', {
                Attribute: conf.colorBy.attribute,
                Palette: palette,
                'View Name': mosaicStore?.currentView?.name,
            });

            return { ...conf, colorBy: { ...conf.colorBy, palette } };
        });
    };

    const changeCurrentView = (view: CustomerView) => {
        if (!mosaicStore) {
            return;
        }

        setOpenOption(undefined);
        setDetailsPanelItem(undefined);

        // Update the store
        mosaicStore.setCurrentView(view);
        mosaicStore.setFilters(undefined);
        mosaicStore.setMosaicMetadata(undefined);
        mosaicStore.setConfig(undefined);
        mosaicStore.setFiltersLogicalOperator(undefined);
        mosaicStore.setSelectedMinClusterSize(undefined);

        // Update the search params
        setSearchParams({ [VIEW_ID_URL_PARAM_NAME]: view.id }, { replace: false });

        // Canceling previous API call to prevent race-condition
        api.cancelRequest('getMosaicData');

        // Re-query the data
        loadViewData(view, false, true);
    };

    const onSearch = useRef(
        debounce((keyword: string) => {
            const value = keyword.trim().toLowerCase();
            eventLogger.log('Searched Keyword', { Value: value, 'View Name': mosaicStore?.currentView?.name });
            setSearchKeyword(value);
        }, 300),
    ).current;

    const formatterName = getFormatterName(mosaicStore?.mosaicMetadata, mosaicStore?.config?.colorBy.attribute);
    const colorByField =
        mosaicStore?.config && mosaicStore?.mosaicMetadata
            ? getField(mosaicStore.mosaicMetadata, mosaicStore.config.colorBy.attribute)
            : undefined;
    const sizeByField =
        mosaicStore?.config && mosaicStore?.mosaicMetadata
            ? getField(mosaicStore.mosaicMetadata, mosaicStore.config.sizeBy.attribute)
            : undefined;
    const { min, max } = calculateMinMax(mosaicStore?.config?.colorBy.attribute, data);

    const isLoading =
        mosaicStore?.filterOperatorsIsLoading ||
        !mosaicStore?.filterOperatorsLoaded ||
        mosaicStore === null ||
        !mosaicStore.mosaicMetadata ||
        !mosaicStore.config ||
        !mosaicStore.currentView ||
        !data ||
        mosaicStore.isDataLoading;

    if (criticalError) {
        throw criticalError;
    }

    return (
        <div className={style.dashboardWrapper}>
            <Topbar setCurrentView={changeCurrentView} onSearch={onSearch} />
            <div style={{ display: 'flex', height: 'calc(100% - 80px)' }}>
                <TreemapLegend
                    min={min}
                    max={max}
                    isLoading={isLoading}
                    palette={mosaicStore?.config?.colorBy.palette}
                    hasUndefined={haveUndefined(mosaicStore?.config?.sizeBy.attribute, data)}
                    fnName={mosaicStore?.config?.colorBy.fn}
                    formatterName={formatterName}
                    field={colorByField}
                    attributeName={mosaicStore?.config?.colorBy.attribute}
                    clusterFieldNamesMapping={mosaicStore?.mosaicMetadata?.dataset.cluster_field_names}
                />
                <Explorer
                    data={data}
                    config={toJS(mosaicStore?.config)}
                    metadata={toJS(mosaicStore?.mosaicMetadata)}
                    searchKeyword={searchKeyword}
                    selectedLayout={selectedLayout}
                    showDetailsPanel={showDetailsPanel}
                    clearSearch={() => setSearchKeyword(undefined)}
                    minValue={min}
                    maxValue={max}
                    dataLoading={Boolean(toJS(mosaicStore?.isDataLoading))}
                    isSideMenuOpen={Boolean(openOption)}
                />
                <InsufficientDataBlock
                    show={showInsufficientData}
                    allowHide={countClusters(data) > 0}
                    onHide={() => setShowInsufficientData(false)}
                    numberOfClusters={countClusters(data)}
                    openFilters={() => mosaicStore?.setIsFilterPopoverOpen(true)}
                />
                <ExplorerBlocker show={Boolean(toJS(mosaicStore?.isFilterPopoverOpen))} />
                <SideMenu
                    customerName={mosaicStore?.currentView?.name}
                    customerLogoUrl={mosaicStore?.currentView?.logo}
                    maxLevel={mosaicStore?.mosaicMetadata?.view.group_by.levels}
                    groupByFields={mosaicStore?.mosaicMetadata?.view.group_by.attributes}
                    selectedGroupByFields={toJS(mosaicStore?.config?.groupBy)}
                    changeGroupBy={changeGroupBy}
                    sizeByFields={mosaicStore?.mosaicMetadata?.view.size_by.attributes}
                    selectedSizeByField={toJS(mosaicStore?.config?.sizeBy.attribute)}
                    setSizeBy={setSizeBy}
                    selectedColorByField={toJS(mosaicStore?.config?.colorBy.attribute)}
                    colorByFields={toJS(mosaicStore?.mosaicMetadata?.view.color_by.attributes)}
                    setSelectedColorByField={setSelectedColorByField}
                    selectedPalette={toJS(mosaicStore?.config?.colorBy.palette)}
                    setSelectedPalette={setSelectedPalette}
                    selectedLayout={selectedLayout}
                    setSelectedLayout={updateSelectedLayout}
                    currentView={mosaicStore?.currentView}
                    selectedColorFunction={toJS(mosaicStore?.config?.colorBy.fn)}
                    setSelectedColorFunction={setSelectedColorFunction}
                    selectedSizeFunction={toJS(mosaicStore?.config?.sizeBy.fn)}
                    setSelectedSizeFunction={setSelectedSizeFunction}
                    fieldsMetadata={toJS(mosaicStore?.mosaicMetadata?.dataset.fields_metadata)}
                    initialAppliedFilter={toJS(mosaicStore?.mosaicMetadata?.view.filters)}
                    dataLoading={Boolean(toJS(mosaicStore?.isDataLoading))}
                    detailsPanelItem={detailsPanelItem}
                    sizeByField={sizeByField}
                    sizeByFieldName={toJS(mosaicStore?.config?.sizeBy.attribute)}
                    openOption={openOption}
                    setOpenOption={setOpenOption}
                    resetConfig={resetConfig}
                    clusterFieldNamesMapping={mosaicStore?.mosaicMetadata?.dataset.cluster_field_names}
                    revision={searchParams.get(REVISION_URL_PARAM_NAME)}
                />
            </div>
        </div>
    );
});

export default Dashboard;
