import React from 'react';
import Slider, {
    SliderThumb,
    SliderMark,
    SliderValueLabel,
    SliderValueLabelProps,
    SliderMarkLabel,
} from '@mui/material/Slider';
import style from './CustomSlider.module.scss';
import { Mark } from '@mui/base';
const MOST_SHOWN_MARKS = 25;

interface UnknownComponentProps extends React.HTMLAttributes<unknown> {}

const ThumbComponent = (props: UnknownComponentProps) => {
    const { children, ...other } = props;

    return (
        <SliderThumb
            {...other}
            style={{
                ...other.style,
                background: '#FFFFFF',
                border: (other as any).ownerState.disabled ? '2px solid #bdbdbd' : '2px solid #43A8E1',
            }}
        >
            {children}
        </SliderThumb>
    );
};

const SliderMarkComponent = (props: UnknownComponentProps) => {
    const { children, ...other } = props;

    return (
        <SliderMark
            {...other}
            style={{
                ...other.style,
                height: 6,
                color: '#fff',
            }}
        >
            {children}
        </SliderMark>
    );
};

const SliderValueLabelComponent = (props: SliderValueLabelProps) => {
    const { children, className, ...other } = props;
    return (
        <SliderValueLabel {...other} className={`${className} ${style.tooltip}`}>
            {children}
        </SliderValueLabel>
    );
};

const SliderMarkLabelComponent = (props: UnknownComponentProps) => {
    const { children, ...other } = props;

    return (
        <div className={style.sliderMarkLabelWrapper}>
            <SliderMarkLabel {...other}>{children}</SliderMarkLabel>
            <div style={other.style} className={style.verticalLine}></div>
        </div>
    );
};

interface CustomSliderComponentProps {
    name?: string;
    value?: number;
    defaultValue?: number;
    min: number;
    max: number;
    onChange?: (e: Event, newValue: number | number[]) => void;
    onChangeCommitted?: (e: React.SyntheticEvent | Event, newValue: number | number[]) => void;
    disabled?: boolean;
    marks?: boolean;
    showMinMaxValues?: boolean;
    showValueLabel?: boolean;
}

const CustomSliderComponent = (props: CustomSliderComponentProps) => {
    const {
        name,
        value,
        min,
        max,
        disabled,
        defaultValue,
        marks,
        showValueLabel,
        showMinMaxValues,
        onChange,
        onChangeCommitted,
    } = props;

    let marksToShow: boolean | Mark[] = Boolean(marks);

    if (marks && showMinMaxValues) {
        marksToShow = [
            { value: min, label: min },
            ...(Math.max(max - min - 1, 0) > MOST_SHOWN_MARKS
                ? []
                : new Array(Math.max(max - min - 1, 0)).fill(0).map((_, i) => ({ value: i + min + 1 }))),
            { value: max, label: max },
        ];
    }

    return (
        <Slider
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            color="neutralSelected"
            valueLabelDisplay={showValueLabel ? 'auto' : 'off'}
            min={min}
            max={max}
            defaultValue={defaultValue}
            marks={marksToShow}
            components={{
                Thumb: ThumbComponent,
                Mark: SliderMarkComponent,
                ValueLabel: SliderValueLabelComponent,
                MarkLabel: SliderMarkLabelComponent,
            }}
            onChangeCommitted={onChangeCommitted}
            getAriaValueText={(value: number) => `${value}`}
            aria-label="Always visible"
        />
    );
};

export default CustomSliderComponent;
