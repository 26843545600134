import Typography from '@mui/material/Typography';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import style from './ErrorBlock.module.scss';
import NoData from './NoData.svg';

const ErrorBlock = () => {
    const { t } = useTranslation();

    return (
        <div className={style.box}>
            <img src={NoData} alt={t('insufficientDataBlock.title')} />
            <Typography variant="h5" component="div" color="darkGray.main" className={style.title}>
                {t('errorBlock.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                <Trans t={t} i18nKey="errorBlock.subtitle" components={{ Bold: <strong /> }}></Trans>
            </Typography>
        </div>
    );
};

export default ErrorBlock;
