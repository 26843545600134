import hashSha256 from '../../../helper/hashSha256';
import searchInGroups from '../../../helper/searchInGroups';
import {
    ExplorerConfig,
    isLeafMosaicRecord,
    isMosaicRecord,
    LeafMosaicRecord,
    MosaicMetadata,
    MosaicRecord,
} from '../../../types';

/**
 * Handling when a user searched a keyword
 *
 * The found clusters will be "exposed" (aka marked)
 *
 * @param foamtreeRef
 * @param data
 * @param metadata
 * @param searchKeyword
 * @returns
 */
export const handleSearch = (
    foamtreeRef: React.MutableRefObject<any>,
    data: MosaicRecord[],
    metadata: MosaicMetadata,
    searchKeyword: string | undefined,
) => {
    if (!foamtreeRef.current || !data || !metadata || !foamtreeRef.current.get('dataObject')) {
        return;
    }

    if (searchKeyword === '') {
        foamtreeRef.current.expose(null);
        return;
    }

    if (!searchKeyword) {
        return;
    }

    const found = searchInGroups(searchKeyword, metadata.dataset.search_fields, foamtreeRef.current.get('dataObject'));
    if (found.length === 0) {
        foamtreeRef.current.expose(null);
        return;
    }

    foamtreeRef.current.set({
        groupExposureScale: 1.015,
        groupExposureShadowSize: 100,
        groupExposureShadowColor: 'rgba(0, 0, 0, 0.2)',
        groupExposureZoomMargin: 0.01,
        exposure: {
            groups: found,
            exposed: true,
        },
    });
};

/**
 * Deleting the details pane items so the generateLastRenderInfo will not take into account the items
 *
 * @param records
 * @returns
 */
const deleteDetailPaneItems = (records: (MosaicRecord | LeafMosaicRecord)[]): (MosaicRecord | LeafMosaicRecord)[] => {
    return records.map((child) => {
        if (isLeafMosaicRecord(child)) {
            delete child.cluster.detailPaneItems;
        } else if (isMosaicRecord(child)) {
            child.groups = deleteDetailPaneItems(child.groups);
        }
        return child;
    });
};

/**
 * Generate a uniq string that represents the data, config and metadata
 *
 * The uniq string will be used for verifying if the data/config/metadata were changed
 * @param data
 * @param config
 * @param metadata
 * @returns
 */
export const generateLastRenderInfo = async (
    data: MosaicRecord[],
    config: ExplorerConfig,
    metadata: MosaicMetadata,
) => {
    const lastRenderInfo =
        JSON.stringify(deleteDetailPaneItems(data)) + JSON.stringify(config) + JSON.stringify(metadata);
    const hash = await hashSha256(lastRenderInfo);
    return hash;
};
