import React, { useState, useEffect, useCallback } from 'react';
import {
    AppliedFilter,
    CustomerView,
    Field,
    RecordHeader,
    RecordHeaderType,
    WeightedLeafMosaicRecordWithParent,
} from '../../../../types';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import formatValue from '../../../../helper/formatValue';
import Subheader from './Subheader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ItemCard from './ItemCard';
import Grid from '@mui/material/Grid';
import style from './DetailsPane.module.scss';
import CustomScrollBar from './CustomScrollbar';
import IconButton from '@mui/material/IconButton';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import api from '../../../../api';
import { generateFiltersClusterHierarchy } from './helper';
import ItemCardSkeleton from './ItemCardSkeleton';
import Skeleton from '@mui/material/Skeleton';

interface DetailsPaneProps {
    detailsPanelItem: WeightedLeafMosaicRecordWithParent | undefined;
    sizeByField: Field | undefined;
    sizeByFieldName: string | undefined;
    filters: AppliedFilter[] | undefined;
    currentView: CustomerView | undefined;
    selectedGroupByFields: string[] | undefined;
    revision: string | null;
}

const DetailsPane = (props: DetailsPaneProps) => {
    const { detailsPanelItem, sizeByField, sizeByFieldName, filters, currentView, selectedGroupByFields, revision } =
        props;
    const [allOpen, setAllOpen] = useState(true);
    const [, updateState] = useState<unknown>();
    const [criticalError, setCriticalError] = useState<Error>();

    const { t } = useTranslation();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        if (
            !detailsPanelItem ||
            !filters ||
            !currentView ||
            !selectedGroupByFields ||
            (detailsPanelItem.cluster.itemsCount && detailsPanelItem.cluster.detailPaneItems)
        ) {
            return;
        }

        api.getClusterItems(
            currentView.id,
            detailsPanelItem.cluster.detailPaneItemIds,
            detailsPanelItem.cluster.leftoverItemIds,
            generateFiltersClusterHierarchy(detailsPanelItem, selectedGroupByFields),
            revision,
        )
            .then((d) => {
                detailsPanelItem.cluster.detailPaneItems = d.items;
                forceUpdate();
            })
            .catch((e) => setCriticalError(e));
    }, [detailsPanelItem, filters, currentView, selectedGroupByFields, revision, forceUpdate]);

    if (!detailsPanelItem || !sizeByField || !sizeByFieldName || !currentView) {
        return null;
    }

    if (criticalError) {
        throw criticalError;
    }

    const sizeByVal = +detailsPanelItem.cluster.dynamicAttributes[sizeByFieldName];
    const ofTotalHeader: RecordHeader = {
        type: RecordHeaderType.KeyValue,
        values: {
            key: t('detailsPanel.subHeaders.ofTotal'),
            value: formatValue(sizeByVal, sizeByField),
        },
    };
    const totalTitleText =
        (detailsPanelItem.cluster.itemsCount || 0) > (detailsPanelItem.cluster.detailPaneItems?.length || 0)
            ? 'part'
            : 'all';

    return (
        <Box sx={{ backgroundColor: 'lightGray.main', height: '100%', overflowY: 'auto' }}>
            <Scrollbars renderTrackVertical={CustomScrollBar}>
                <Box className={style.header}>
                    <Box sx={{ p: 1 }}>
                        <Typography
                            variant="h5"
                            gutterBottom
                            component="div"
                            color="darkGray.main"
                            className={style.title}
                        >
                            {detailsPanelItem.label}
                        </Typography>
                        <Grid container direction="row" justifyContent="space-evenly" alignItems="center" wrap="nowrap">
                            <Subheader header={ofTotalHeader} />
                            {detailsPanelItem.cluster.headers.flatMap((h) => [
                                <Divider orientation="vertical" flexItem key={JSON.stringify(h) + '-divider'} />,
                                <Subheader header={h} key={JSON.stringify(h)} />,
                            ])}
                        </Grid>
                        {detailsPanelItem.cluster.itemsCount && detailsPanelItem.cluster.detailPaneItems ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton size="small" onClick={() => setAllOpen((prev) => !prev)}>
                                    {allOpen ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                                </IconButton>
                                <Typography color="darkGray.main" component="div" className={style.totalView}>
                                    {t(`detailsPanel.totalItems.${totalTitleText}`, {
                                        count: detailsPanelItem.cluster.detailPaneItems?.length || 0,
                                        total: detailsPanelItem.cluster.itemsCount,
                                    })}
                                </Typography>
                            </div>
                        ) : (
                            <Skeleton
                                animation="wave"
                                variant="text"
                                sx={{ height: 35, width: 150, backgroundColor: '#F4F4F4' }}
                            />
                        )}
                    </Box>
                    <Divider />
                </Box>

                <Box sx={{ p: 1 }}>
                    {detailsPanelItem.cluster.detailPaneItems && detailsPanelItem.cluster.itemsCount ? (
                        detailsPanelItem.cluster.detailPaneItems.map((item) => (
                            <ItemCard item={item} key={item._id} allOpen={allOpen} />
                        ))
                    ) : (
                        <>
                            <ItemCardSkeleton />
                            <ItemCardSkeleton />
                        </>
                    )}
                </Box>
            </Scrollbars>
        </Box>
    );
};

export default DetailsPane;
