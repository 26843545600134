import { Field, LogicalOperator } from '../types';

export const functions: { [k: string]: (e: any) => any } = {
    identity: (e: any) => e,
    'pow(.75)': (e: number) => Math.pow(e, 0.75),
    sqrt: Math.sqrt,
    'pow(.33)': (e: number) => Math.pow(e, 0.33),
    'pow(.25)': (e: number) => Math.pow(e, 0.25),
    log: (e: number) => Math.log(e * 100 + 1),
};

export const functionsOrder = ['log', 'pow(.25)', 'pow(.33)', 'sqrt', 'identity', 'pow(.75)'];

export const palettes = [
    { label: 'Warm sequence', name: 'tol-sq' },
    { label: 'Diverging', name: 'tol-dv' },
    { label: 'Rainbow', name: 'tol-rainbow' },
    // ----------------------------------------- New colors
    { label: 'Accent', name: 'cb-Accent' },
    { label: 'Blues', name: 'cb-Blues' },
    { label: 'cb-BrBG', name: 'cb-BrBG' },
    { label: 'cb-BuGn', name: 'cb-BuGn' },
    { label: 'cb-BuPu', name: 'cb-BuPu' },
    { label: 'Dark2', name: 'cb-Dark2' },
    { label: 'cb-GnBu', name: 'cb-GnBu' },
    { label: 'Greens', name: 'cb-Greens' },
    { label: 'Greys', name: 'cb-Greys' },
    { label: 'cb-OrRd', name: 'cb-OrRd' },
    { label: 'Oranges', name: 'cb-Oranges' },
    { label: 'cb-PRGn', name: 'cb-PRGn' },
    { label: 'Paired', name: 'cb-Paired' },
    { label: 'Pastel1', name: 'cb-Pastel1' },
    { label: 'Pastel2', name: 'cb-Pastel2' },
    { label: 'cb-PiYG', name: 'cb-PiYG' },
    { label: 'cb-PuBu', name: 'cb-PuBu' },
    { label: 'cb-PuBuGn', name: 'cb-PuBuGn' },
    { label: 'cb-PuOr', name: 'cb-PuOr' },
    { label: 'cb-PuRd', name: 'cb-PuRd' },
    { label: 'Purples', name: 'cb-Purples' },
    { label: 'cb-RdBu', name: 'cb-RdBu' },
    { label: 'cb-RdGy', name: 'cb-RdGy' },
    { label: 'cb-RdPu', name: 'cb-RdPu' },
    { label: 'cb-RdYlBu', name: 'cb-RdYlBu' },
    { label: 'cb-RdYlGn', name: 'cb-RdYlGn' },
    { label: 'Reds', name: 'cb-Reds' },
    { label: 'Set1', name: 'cb-Set1' },
    { label: 'Set2', name: 'cb-Set2' },
    { label: 'Set3', name: 'cb-Set3' },
    { label: 'Spectral', name: 'cb-Spectral' },
    { label: 'cb-YlGn', name: 'cb-YlGn' },
    { label: 'cb-YlGnBu', name: 'cb-YlGnBu' },
    { label: 'cb-YlOrBr', name: 'cb-YlOrBr' },
    { label: 'cb-YlOrRd', name: 'cb-YlOrRd' },
    { label: 'mpn65', name: 'mpn65' },
    { label: 'Rainbow 95', name: 'rainbow' },
    { label: 'Solarized Accent', name: 'sol-accent' },
    { label: 'Solarized Base', name: 'sol-base' },
    { label: 'tol', name: 'tol' },
];

export const formatters: { [name: string]: (val: number, field: Field) => any } = {
    numberToStringWithUnit: (val: number, field: Field) => {
        if (val === undefined || val === null) {
            return 'n/a';
        }

        const { decimals, unit } = field;
        const safeDecimals = decimals || 0;

        let fixedVal = val.toFixed(Math.max(0, safeDecimals));
        if (+fixedVal === 0 && val > 0) {
            fixedVal = '<0';
            if (safeDecimals) {
                fixedVal += '.' + '0'.repeat(safeDecimals - 1) + '1';
            }
        }

        return fixedVal + (unit ? unit : '');
    },
    identity: (val: number, _field: Field) => val,
};

export const preprocessors = {
    stringToNumber: (s: string) => {
        const clean = s.replace(/( )|(N\/R)/g, '').replace(',', '.');
        return clean.length > 0 ? parseFloat(clean) : undefined;
    },
};

export const undefinedColor = '#cccccc';

export const defaultSizeByFnName = 'identity';

export const CLUSTER_SIZE_FILTER_NAME = 'clusterSize';
export const DEFAULT_MIN_FILTER = 0;
export const DEFAULT_MIN_CLUSTER_SIZE = 2;
export const DEFAULT_MAX_CLUSTER_SIZE = Infinity;
export const DEFAULT_MAX_FILTER = Infinity;
export const DEFAULT_MAX_CLUSTER_SIZE_INCREMENT = 3;
export const DEFAULT_FILTERS_LOGICAL_OPERATOR = LogicalOperator.intersection;
export const MIN_NUMBER_OF_CLUSTERS_TO_SHOW = 2;
