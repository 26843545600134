import React, { useState, useEffect, useContext } from 'react';
import logo from '../common/logo.png';
import { useFormik } from 'formik';
import { SignInUser } from '../../types';
import signInSchema from '../../formSchemas/signInSchema';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import style from './Login.module.scss';
import login from '../../auth/login';
import logout from '../../auth/logout';
import { MosaicStoreContext } from '../../stores/MosaicStore';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

const InputSx = { minWidth: 300 };

const Login = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const mosaicStore = useContext(MosaicStoreContext);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const formik = useFormik<SignInUser>({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: signInSchema,
        onSubmit: (user: SignInUser) => {
            setIsLoading(true);
            setIsError(false);
            api.signIn(user.email, user.password)
                .then((response) => {
                    const { access_token } = response;
                    login(access_token);
                    navigate({ pathname: '/', search: '' });
                })
                .catch((e) => {
                    console.error(e);
                    setIsLoading(false);
                    setIsError(true);
                });
        },
    });

    useEffect(() => {
        logout();
        mosaicStore?.reset();
        mosaicStore?.logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className={style.logoWrapper}>
                <img src={logo} alt="Logo" width="195" />
            </div>
            <form onSubmit={formik.handleSubmit} className={style.form}>
                <TextField
                    name="email"
                    label={t('login.email')}
                    type="email"
                    required
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.errors.email}
                    error={Boolean(formik.errors.email) || isError}
                    sx={InputSx}
                    disabled={isLoading}
                />
                <TextField
                    name="password"
                    label={t('login.password')}
                    type="password"
                    required
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.errors.password}
                    error={Boolean(formik.errors.password) || isError}
                    sx={InputSx}
                    disabled={isLoading}
                />
                <LoadingButton loading={isLoading} type="submit" variant="outlined">
                    {t('login.signInButton')}
                </LoadingButton>

                {isError && (
                    <Typography variant="subtitle1" gutterBottom component="div" color="error.main">
                        {t('login.defaultErrorMessage')}
                    </Typography>
                )}
            </form>
        </div>
    );
});

export default Login;
