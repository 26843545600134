import {
    AppliedFilter,
    AutocompleteResponse,
    ClusterItemsResponse,
    ClustersResponse,
    CustomerView,
    FilterOperators,
    FiltersClusterHierarchy,
    LogicalOperator,
    MosaicMetadata,
    SignInResponse,
    UserDetails,
} from '../types';
import axios, { AxiosInstance } from 'axios';
import { CLUSTER_SIZE_FILTER_NAME } from '../helper/consts';
import withCancel from './withCancel';

export class ApiClient {
    private apiClient: AxiosInstance;
    public abortControllersMap = new Map<string, AbortController>();

    constructor() {
        this.apiClient = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
        });

    }

    /**
     * Sens a sign in request to the backend
     *
     * @param email
     * @param password
     * @returns sign in response
     */
    async signIn(email: string, password: string): Promise<SignInResponse> {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', email);
        params.append('password', password);

        const res = await this.apiClient.post('/auth/login', params);
        return res.data;
    }

    /**
     * Get customer's views
     *
     * @returns
     */
    async getCustomerView(): Promise<CustomerView[]> {
        const res = await this.apiClient.get(`/views`);
        return res.data;
    }

    /**
     * Get the mosaic metadata
     *
     * @param viewId
     * @returns
     */
    @withCancel()
    async getMosaicMetadata(viewId: string, abortController?: AbortController): Promise<MosaicMetadata> {
        const res = await this.apiClient.get(`/views/${viewId}/metadata`, { signal: abortController?.signal });
        return res.data;
    }

    /**
     * Get the mosaic data (with applied filters)
     *
     * @param viewId
     * @param groupBy
     * @param filters
     * @param revision
     * @returns
     */
    @withCancel()
    async getMosaicData(
        viewId: string,
        groupBy: string[],
        filters: AppliedFilter[],
        revision: string | null,
        filtersLogicalOperator: LogicalOperator,
        shouldSendMinClusterSize: boolean,
        selectedMinClusterSize?: number,
        abortController?: AbortController,
    ): Promise<ClustersResponse> {
        const filtersWithoutClusterSize = filters.filter((f) => f.field !== CLUSTER_SIZE_FILTER_NAME);
        let url = `/views/${viewId}/clusters`;
        if (revision) {
            url += `?revision=${revision}`;
        }

        const res = await this.apiClient.post(
            url,
            {
                group_by: groupBy.filter(Boolean),
                min_cluster_size: selectedMinClusterSize,
                filters: filtersWithoutClusterSize,
                filters_logical_operator: filtersLogicalOperator,
                should_send_min_cluster_size: shouldSendMinClusterSize,
            },
            { signal: abortController?.signal },
        );

        // Debug print if the headers exists
        if (
            res.headers['x-total-num-items'] !== undefined &&
            res.headers['x-total-leftovers-items'] !== undefined &&
            res.headers['x-shown-items'] !== undefined
        ) {
            const presentOfItemShown = (+res.headers['x-shown-items'] / +res.headers['x-total-num-items']) * 100;
            const presentOfLeftovers =
                (+res.headers['x-total-leftovers-items'] / +res.headers['x-total-num-items']) * 100;
            const presentOfItemShownNoLeftovers =
                ((+res.headers['x-shown-items'] - +res.headers['x-total-leftovers-items']) /
                    +res.headers['x-total-num-items']) *
                100;

            const logMessage = [
                `Total number of items: ${res.headers['x-total-num-items']}`,
                `Total number of leftovers added: ${res.headers['x-total-leftovers-items']}`,
                `Total shown items: ${res.headers['x-shown-items']}`,
                `% of items shown: ${presentOfItemShown.toFixed(2)}%`,
                `% of leftover items: ${presentOfLeftovers.toFixed(2)}%`,
                `% of items shown w/o leftovers: ${presentOfItemShownNoLeftovers.toFixed(2)}%`,
                `Min cluster size: ${selectedMinClusterSize || res.data.min_cluster_size?.default}`,
            ];
            console.log(logMessage.join(' | '));
        }

        return res.data;
    }

    async getAutocompleteSuggestion(
        viewId: string,
        field: string,
        value: string,
        revision: string | null,
    ): Promise<AutocompleteResponse> {
        let url = `/views/${viewId}/autocomplete`;
        if (revision) {
            url += `?revision=${revision}`;
        }
        const res = await this.apiClient.post(url, {
            field,
            value,
        });
        return res.data;
    }

    /**
     * Get allowed filter operators
     * @returns
     */
    async getFilterOperators(): Promise<FilterOperators> {
        const res = await this.apiClient.get(`/operators`);
        return res.data;
    }

    /**
     * Get user details
     * @returns
     */
    async getUserDetails(): Promise<UserDetails> {
        const res = await this.apiClient.get(`/auth/me`);
        return res.data;
    }

    /**
     * Get cluster's items
     * @param viewId
     * @param filters
     * @param filtersClusterHierarchy
     * @returns
     */
    async getClusterItems(
        viewId: string,
        itemIds: string[],
        leftoverItemIds: string[],
        filtersClusterHierarchy: FiltersClusterHierarchy,
        revision: string | null,
    ): Promise<ClusterItemsResponse> {
        let url = `/views/${viewId}/clusters/items`;
        if (revision) {
            url += `?revision=${revision}`;
        }

        const res = await this.apiClient.post(url, {
            ids: itemIds,
            filters_cluster_hierarchy: filtersClusterHierarchy,
            leftover_ids: leftoverItemIds,
        });
        return res.data;
    }

    /**
     * Set the token for future requests
     *
     * @param token
     */
    setToken(token: string) {
        this.apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    /**
     * Cancel given request
     *
     * @param requestName
     */
    cancelRequest(requestName: string) {
        if (this.abortControllersMap.has(requestName)) {
            this.abortControllersMap.get(requestName)?.abort();
            this.abortControllersMap.delete(requestName);
        }
    }
}

export default new ApiClient();
