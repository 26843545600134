import React from 'react';
import { RecordHeader, RecordHeaderType } from '../../../../types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import style from './Subheader.module.scss';
import LightTooltip from '../../../common/LightTooltip';

interface SubheaderProps {
    header: RecordHeader;
}

const Subheader: React.FC<SubheaderProps> = (props) => {
    const { header } = props;

    if (header.type === RecordHeaderType.KeyValue) {
        return (
            <Grid item xs zeroMinWidth className={style.gridItem}>
                <Typography variant="h6" component="div" color="neutralSelected.main" className={style.value}>
                    {header.values.value}
                </Typography>

                <LightTooltip title={header.values.key.trim()} arrow placement="bottom">
                    <Typography variant="subtitle1" component="div" color="neutralSelected.main" className={style.key}>
                        {header.values.key.trim()}
                    </Typography>
                </LightTooltip>
            </Grid>
        );
    }
    return null;
};

export default Subheader;
