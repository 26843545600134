import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NoMatchPage from './routes/NoMatchPage';
import DashboardPage from './routes/DashboardPage';
import PrivateRoute from './components/PrivateRoute';
import { basenameForRoutes, routes } from './components/consts';
import LoginPage from './routes/LoginPage';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

// Generic site style
import './index.scss';
import AuthProvider from './components/AuthProvider';
import ErrorBoundary from './components/ErrorBoundary';
import NoViewsPage from './routes/NoViewsPage';
import './i18n';
import { MosaicStoreContextProvider } from './stores/MosaicStore';
import theme from './theme';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <CssBaseline />
                <ErrorBoundary>
                    <AuthProvider>
                        <MosaicStoreContextProvider>
                            <BrowserRouter basename={basenameForRoutes}>
                                <Routes>
                                    <Route path="/" element={<PrivateRoute />}>
                                        <Route path="/" element={<DashboardPage />} />
                                    </Route>
                                    <Route path={routes.login} element={<LoginPage />} />
                                    <Route path={routes.noViews} element={<NoViewsPage />} />
                                    <Route path="*" element={<NoMatchPage />} />
                                </Routes>
                            </BrowserRouter>
                        </MosaicStoreContextProvider>
                    </AuthProvider>
                </ErrorBoundary>
            </LocalizationProvider>
        </ThemeProvider>
    </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
