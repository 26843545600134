import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: { fontFamily: 'DM Sans,Roboto,Helvetica,Arial,sans-serif' },
    palette: {
        neutral: {
            main: '#5E6A7D',
            contrastText: '#fff',
        },
        neutralSelected: {
            main: '#43A8E1',
            contrastText: '#fff',
        },
        mainBlue: { main: '#2463EB', contrastText: '#fff' },
        buttonGray: { main: '#FAFAF9', contrastText: '#404B5C' },
        lightGray: {
            main: '#f6f6f6',
            contrastText: '#fff',
        },
        darkGray: { main: '#404B5C', contrastText: '#fff' },
    },
});

export default theme;
