import TextField from '@mui/material/TextField';
import React, { useContext } from 'react';
import { SemanticType } from '../../../../../../types';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import FilterAutocomplete from './FilterAutocomplete';
import { observer } from 'mobx-react-lite';
import { MosaicStoreContext } from '../../../../../../stores/MosaicStore';
import { toJS } from 'mobx';
import { useSearchParams } from 'react-router-dom';
import { REVISION_URL_PARAM_NAME } from '../../../../../consts';
import { AUTOCOMPLETE_OPERATORS_FOR_STRING, CUSTOM_OPERATORS_FN } from '../../../../filtersHelper';

interface FilterValueProps {
    name: string;
    value: string | number | string[];
    semanticType: SemanticType | undefined;
    error: boolean;
    errorMessage: string | undefined;
    operator: string;
    field: string;
    onChange: () => void;
    onBlur: () => void;
    setFieldValue: (field: string, value: any) => void;
}

const FilterValue = observer((props: FilterValueProps) => {
    const { semanticType, name, value, error, errorMessage, operator, field, onChange, onBlur, setFieldValue } = props;

    const { t } = useTranslation();
    const mosaicStore = useContext(MosaicStoreContext);
    const [searchParams] = useSearchParams();

    if (!semanticType) {
        return <TextField label={t('filters.valuePlaceholder')} variant="outlined" disabled fullWidth />;
    }

    if (CUSTOM_OPERATORS_FN[operator]) {
        return <TextField label={CUSTOM_OPERATORS_FN[operator].generateView()} variant="outlined" disabled fullWidth />;
    }

    if (semanticType === SemanticType.NUMBER) {
        return (
            <TextField
                variant="outlined"
                type="number"
                name={name}
                value={value}
                onChange={onChange}
                error={error}
                helperText={error && errorMessage && t(errorMessage, { defaultValue: errorMessage })}
                fullWidth
            />
        );
    }

    if (
        semanticType === SemanticType.ARRAY ||
        (semanticType === SemanticType.STRING && AUTOCOMPLETE_OPERATORS_FOR_STRING.has(operator))
    ) {
        return (
            <FilterAutocomplete
                name={name}
                value={Array.isArray(value) ? value : value ? [`${value}`] : []}
                field={field}
                viewId={toJS(mosaicStore?.currentView?.id) || ''}
                error={error}
                errorMessage={errorMessage}
                revision={searchParams.get(REVISION_URL_PARAM_NAME)}
                setFieldValue={setFieldValue}
            />
        );
    }

    if (semanticType === SemanticType.STRING || semanticType === SemanticType.LONG_STRING) {
        return (
            <TextField
                error={error}
                helperText={error && errorMessage && t(errorMessage, { defaultValue: errorMessage })}
                variant="outlined"
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fullWidth
            />
        );
    }

    if (semanticType === SemanticType.DATE) {
        return (
            <DesktopDatePicker
                inputFormat="DD MMM YYYY"
                value={value !== '' ? moment(value) : null}
                onChange={(value) => {
                    setFieldValue(name, value?.toISOString());
                }}
                renderInput={(params) => (
                    <TextField
                        fullWidth
                        name={name}
                        {...params}
                        error={error}
                        helperText={error && errorMessage && t(errorMessage, { defaultValue: errorMessage })}
                    />
                )}
            />
        );
    }

    if (semanticType === SemanticType.BOOL) {
        return (
            <Select
                name={name}
                // Convert the bool to string
                value={`${value}`}
                onChange={(e: SelectChangeEvent) => {
                    if (e.target.value === '') {
                        setFieldValue(name, e.target.value);
                    } else {
                        // Convert bool as string to bool
                        setFieldValue(name, e.target.value === 'true');
                    }
                }}
                fullWidth
            >
                <MenuItem value="">
                    <em>{t('filters.booleanPlaceholder')}</em>
                </MenuItem>
                <MenuItem value="true">{t('filters.predefinedValues.bool.true')}</MenuItem>
                <MenuItem value="false">{t('filters.predefinedValues.bool.false')}</MenuItem>
            </Select>
        );
    }

    throw new Error(`No filter value was rendered for ${name} - ${operator} - ${field} - ${semanticType}`);
});

export default FilterValue;
