import React from 'react';
import { useCookies } from 'react-cookie';
import { decodeToken } from 'react-jwt';
import api from '../../api';
import eventLogger from '../../eventLogger';
import { DecodedJWT } from '../../types';
import { JWT_TOKEN_COOKIE_NAME } from '../consts';
import errorLogger from '../ErrorBoundary/errorLogger';

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const [cookies] = useCookies([JWT_TOKEN_COOKIE_NAME]);

    // If we have token
    if (cookies[JWT_TOKEN_COOKIE_NAME]) {
        const decoded = decodeToken(cookies[JWT_TOKEN_COOKIE_NAME]);
        if (decoded) {
            const userId = (decoded as DecodedJWT).sub;
            eventLogger.setUserId(userId);
            errorLogger.setUserId(userId);
            api.setToken(cookies[JWT_TOKEN_COOKIE_NAME]);
        }
    }

    return <>{props.children}</>;
};

export default AuthProvider;
