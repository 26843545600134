import React from 'react';
import MenuItemButton from './MenuItemButton';
import TuneIcon from '@mui/icons-material/Tune';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useSpring, animated } from 'react-spring';

import style from './SideMenu.module.scss';
import {
    ExplorerLayout,
    CustomerView,
    FieldsMetadata,
    AppliedFilter,
    Field,
    SideMenuOpenOption,
    WeightedLeafMosaicRecordWithParent,
    ClusterFieldNamesMapping,
} from '../../../types';
import Divider from '@mui/material/Divider';
import ExplorerConfigPanel from '../ExplorerConfigPanel';
import DetailsPane from './DetailsPane';
import { useTranslation } from 'react-i18next';

const createToggleFunction =
    (option: SideMenuOpenOption): React.SetStateAction<SideMenuOpenOption | undefined> =>
    (prev: SideMenuOpenOption | undefined) => {
        if (prev === option) {
            return undefined;
        }
        return option;
    };

interface SideMenuProps {
    customerName: string | undefined;
    customerLogoUrl: string | undefined;
    maxLevel: number | undefined;
    groupByFields: string[] | undefined;
    selectedGroupByFields: string[] | undefined;
    selectedSizeByField: string | undefined;
    sizeByFields: string[] | undefined;
    selectedSizeFunction: string | undefined;
    selectedColorByField: string | undefined;
    colorByFields: string[] | undefined;
    selectedPalette: string | undefined;
    selectedColorFunction: string | undefined;
    selectedLayout: ExplorerLayout;
    currentView: CustomerView | undefined;
    fieldsMetadata: FieldsMetadata | undefined;
    initialAppliedFilter: AppliedFilter[] | undefined;
    dataLoading: boolean;
    detailsPanelItem: WeightedLeafMosaicRecordWithParent | undefined;
    sizeByField: Field | undefined;
    sizeByFieldName: string | undefined;
    openOption: SideMenuOpenOption | undefined;
    setSizeBy: (field: string) => void;
    changeGroupBy: (index: number, value: string) => void;
    setSelectedColorByField: (field: string) => void;
    setSelectedPalette: (palette: string) => void;
    setSelectedLayout: (layout: ExplorerLayout) => void;
    setSelectedColorFunction: (fnName: string) => void;
    setSelectedSizeFunction: (fnName: string) => void;
    resetConfig: () => void;
    setOpenOption: React.Dispatch<React.SetStateAction<SideMenuOpenOption | undefined>>;
    clusterFieldNamesMapping: ClusterFieldNamesMapping | undefined;
    revision: string | null;
}

const SideMenu = (props: SideMenuProps) => {
    const {
        customerName,
        customerLogoUrl,
        maxLevel,
        groupByFields,
        selectedGroupByFields,
        selectedSizeByField,
        sizeByFields,
        selectedColorByField,
        colorByFields,
        selectedPalette,
        selectedLayout,
        currentView,
        selectedColorFunction,
        selectedSizeFunction,
        fieldsMetadata,
        initialAppliedFilter,
        dataLoading,
        detailsPanelItem,
        openOption,
        sizeByField,
        sizeByFieldName,
        clusterFieldNamesMapping,
        revision,
        changeGroupBy,
        setSizeBy,
        setSelectedColorByField,
        setSelectedPalette,
        setSelectedLayout,
        setSelectedColorFunction,
        setSelectedSizeFunction,
        resetConfig,
        setOpenOption,
    } = props;
    const { t } = useTranslation();

    const contentStyle = useSpring({
        width: Boolean(openOption) ? 410 : 0,
        height: '100%',
    });

    const isLoading =
        maxLevel === undefined ||
        groupByFields === undefined ||
        selectedGroupByFields === undefined ||
        selectedSizeByField === undefined ||
        sizeByFields === undefined ||
        selectedColorByField === undefined ||
        selectedColorFunction === undefined ||
        selectedSizeFunction === undefined ||
        colorByFields === undefined ||
        selectedPalette === undefined ||
        customerName === undefined ||
        customerLogoUrl === undefined ||
        currentView === undefined ||
        fieldsMetadata === undefined ||
        initialAppliedFilter === undefined;

    return (
        <div className={style.wrapper}>
            <animated.div style={contentStyle} className={style.menu}>
                {openOption === SideMenuOpenOption.Config && (
                    <ExplorerConfigPanel
                        dataLoading={dataLoading}
                        customerName={customerName}
                        customerLogoUrl={customerLogoUrl}
                        maxLevel={maxLevel}
                        groupByFields={groupByFields}
                        selectedGroupByFields={selectedGroupByFields}
                        changeGroupBy={changeGroupBy}
                        sizeByFields={sizeByFields}
                        selectedSizeByField={selectedSizeByField}
                        setSizeBy={setSizeBy}
                        selectedColorByField={selectedColorByField}
                        colorByFields={colorByFields}
                        setSelectedColorByField={setSelectedColorByField}
                        selectedPalette={selectedPalette}
                        setSelectedPalette={setSelectedPalette}
                        selectedLayout={selectedLayout}
                        setSelectedLayout={setSelectedLayout}
                        currentView={currentView}
                        selectedColorFunction={selectedColorFunction}
                        setSelectedColorFunction={setSelectedColorFunction}
                        selectedSizeFunction={selectedSizeFunction}
                        setSelectedSizeFunction={setSelectedSizeFunction}
                        fieldsMetadata={fieldsMetadata}
                        initialAppliedFilter={initialAppliedFilter}
                        resetConfig={resetConfig}
                        clusterFieldNamesMapping={clusterFieldNamesMapping}
                    />
                )}
                {openOption === SideMenuOpenOption.DetailsPane && (
                    <DetailsPane
                        detailsPanelItem={detailsPanelItem}
                        sizeByField={sizeByField}
                        sizeByFieldName={sizeByFieldName}
                        filters={initialAppliedFilter}
                        currentView={currentView}
                        selectedGroupByFields={selectedGroupByFields}
                        revision={revision}
                    />
                )}
            </animated.div>

            <Divider orientation="vertical" flexItem />

            <div className={style.menuItems}>
                <MenuItemButton
                    tooltipText={t('explorerConfigPanel.title')}
                    Icon={TuneIcon}
                    isSelected={openOption === SideMenuOpenOption.Config}
                    disabled={isLoading}
                    onClick={() => setOpenOption(createToggleFunction(SideMenuOpenOption.Config))}
                />
                <MenuItemButton
                    tooltipText={t('detailsPanel.title')}
                    Icon={TextSnippetOutlinedIcon}
                    isSelected={openOption === SideMenuOpenOption.DetailsPane}
                    disabled={isLoading || !Boolean(detailsPanelItem)}
                    onClick={() => setOpenOption(createToggleFunction(SideMenuOpenOption.DetailsPane))}
                />
            </div>
        </div>
    );
};

export default SideMenu;
