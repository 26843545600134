import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

export const ViewDisplayXS = { p: 1, display: 'flex' };
export const SideMenuXS = { ...ViewDisplayXS, marginLeft: 'auto', pr: 0 };
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: 'none',
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontWeight: 500,
        fontSize: 20,
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));
