import axios, { AxiosError } from 'axios';
import LogRocket from 'logrocket';

class ErrorLogger {
    private apiKeys = {
        prod: 'hgjiwj/mosaic-prod',
        dev: 'hgjiwj/mosaic-dev',
    };

    private userId?: string;

    constructor() {
        const env = window.location.host === 'mosaic.ask-ai.co' ? 'prod' : 'dev';
        LogRocket.init(this.apiKeys[env], {
            network: {
                requestSanitizer: (request) => {
                    // Remove the authorization header
                    if (request.headers['Authorization']) {
                        request.headers['Authorization'] = 'AUTHORIZED';
                    }

                    // If login - we'll redact the password
                    if (request.url.endsWith('/auth/login') && request.body) {
                        const params = new URLSearchParams(request.body);
                        params.set('password', 'REDACTED');

                        request.body = params.toString();
                    }
                    return request;
                },
            },
        });
    }

    setUserId(userId: string) {
        LogRocket.identify(userId);
        this.userId = userId;
    }

    setUserExtraDetails(org: string, userName: string) {
        if (!this.userId) {
            return;
        }
        LogRocket.identify(this.userId, { name: userName, org });
    }

    captureException(error: Error, errorInfo?: React.ErrorInfo) {
        const extra: {
            [tagName: string]: string | number | boolean;
        } = {};

        if (errorInfo) {
            extra.componentStack = errorInfo.componentStack;
        }

        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            extra.axiosError = true;

            // Extract the request ID to be attached to the report
            const requestId = axiosError.response?.headers['x-request-id'];
            if (requestId) {
                extra.requestId = requestId;
            }
        }

        LogRocket.captureException(error, { extra });
    }
}

export default new ErrorLogger();
