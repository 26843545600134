import React from 'react';
import Lottie from 'lottie-react';
import animation from './circular-loader.json';
import ReactDOM from 'react-dom';

const LoadingExplorerAnimation = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
            <div style={{ margin: 'auto', height: 200, width: 200 }}>
                <Lottie animationData={animation} loop={true} />
            </div>
        </div>
    );
};

export default LoadingExplorerAnimation;

interface LoadingExplorerAnimationPortalProps {
    elementId: string;
}
export const LoadingExplorerAnimationPortal = (props: LoadingExplorerAnimationPortalProps) => {
    const aElm = document.getElementById(props.elementId);
    if (!aElm) {
        return null;
    }

    return ReactDOM.createPortal(<LoadingExplorerAnimation />, aElm);
};
