import React from 'react';
import Typography from '@mui/material/Typography';
import style from './GroupTitle.module.scss';

interface GroupTitleProps {
    children: React.ReactNode;
}

const GroupTitle: React.FC<GroupTitleProps> = (props) => {
    return (
        <Typography variant="subtitle1" gutterBottom component="div" color="darkGray.main" className={style.groupTitle}>
            {props.children}
        </Typography>
    );
};

export default GroupTitle;
