import Typography from '@mui/material/Typography';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import style from './InsufficientDataBlock.module.scss';
import NoData from './NoData.svg';

interface InsufficientDataBlockProps {
    show: boolean;
    allowHide: boolean;
    numberOfClusters: number;
    onHide: () => void;
    openFilters: () => void;
}

const InsufficientDataBlock = (props: InsufficientDataBlockProps) => {
    const { show, allowHide, numberOfClusters, onHide, openFilters } = props;
    const { t } = useTranslation();
    if (!show) {
        return null;
    }

    return (
        <div className={style.box}>
            <img src={NoData} alt={t('insufficientDataBlock.title')} />
            <Typography variant="h5" component="div" color="darkGray.main" className={style.title}>
                {t('insufficientDataBlock.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                <Trans
                    t={t}
                    i18nKey="insufficientDataBlock.subTitle"
                    components={{ Link: <span className={style.link} onClick={openFilters} /> }}
                ></Trans>
            </Typography>
            {allowHide && (
                <Typography variant="subtitle1" gutterBottom>
                    <Trans
                        t={t}
                        i18nKey="insufficientDataBlock.viewData"
                        values={{ numberOfClusters }}
                        components={{ Link: <span className={style.link} onClick={onHide} /> }}
                    ></Trans>
                </Typography>
            )}
        </div>
    );
};

export default InsufficientDataBlock;
