import { JWT_TOKEN_COOKIE_NAME } from '../components/consts';
import cookies from './cookies';

/**
 * Handling logging out the user
 */
const logout = async () => {
    cookies.remove(JWT_TOKEN_COOKIE_NAME);
};

export default logout;
